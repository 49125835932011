import React, { useState, useEffect } from "react";
import { getCookie } from "../global/Global";

const Footer = ({ onReply, threadid, forumid, user, title, collapsed }) => {
  return (
    <footer className="footer">
      <div>Talkvio Engine {process.env.REACT_APP_GIT_DESCRIBE}</div>
    </footer>
  );
};
export default Footer;
