import React, { useEffect, useState, useRef, useContext } from "react";
import RecentPosts from "./RecentPosts";
import useScroll from "../global/UseScroll";
import useResize from "../global/UseResize";
import { LoginContext } from "./Login";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import { isMobile } from "../global/Global";

const RightPanel = () => {
  const { user } = useContext(LoginContext);
  const rightPanel = useRef();
  const DEFAULT_PANEL_OFFSET = 45;
  const topOffset = useRef(DEFAULT_PANEL_OFFSET);
  const onSize = useRef(1420);
  const isDisplay = useRef(false);
  const [stickyDisplay, setStickyDisplay] = useState(false);

  window.setRightPanelTopOffet = (value) => {
    if (!rightPanel.current) return;

    topOffset.current = value;
    rightPanel.current.style.top = `${value}px`;
    onScroll(window.pageXOffset, window.pageYOffset);
  };

  window.resetRightPanelTopOffet = () => {
    topOffset.current = DEFAULT_PANEL_OFFSET;
    window.setRightPanelTopOffet(DEFAULT_PANEL_OFFSET);
  };

  window.setRightPanelDisplay = (d) => {
    if (!rightPanel.current) return;

    isDisplay.current = d;
    resizeTrigger(window.innerWidth);
  };

  window.setRightPanelOnSize = (size = 1420) => {
    onSize.current = size;
    resizeTrigger(window.innerWidth);
  };

  useEffect(() => {
    // default
    window.setRightPanelTopOffet(topOffset.current);
  }, []);

  const resizeTrigger = (width) => {
    if (width >= onSize.current && isDisplay.current) {
      if (!stickyDisplay) {
        setStickyDisplay(true);
      }
      if (rightPanel?.current) rightPanel.current.style.display = "block";
    } else {
      if (stickyDisplay && rightPanel?.current) {
        rightPanel.current.style.display = "none";
      }
    }
  };
  useResize(resizeTrigger);

  const onScroll = (x, y) => {
    if (!rightPanel?.current || !topOffset?.current) return;

    // const postListPosition = postsListRef.current.parentElement.getBoundingClientRect();
    const maxOffsetY =
      topOffset.current -
      DEFAULT_PANEL_OFFSET +
      (window.talkvioHeaderOffset || 0);
    if (
      y > maxOffsetY &&
      rightPanel.current.style.transform == `translateY(-${maxOffsetY}px)`
    ) {
      return;
    }
    const newPosition = Math.min(y, maxOffsetY);
    rightPanel.current.style.transform = `translateY(-${newPosition}px)`;
    const TOP_HEADER_OFFSET = 70 - (window.talkvioHeaderOffset || 0);
    rightPanel.current.style.maxHeight = `calc(100% - ${TOP_HEADER_OFFSET + maxOffsetY - newPosition}px)`;
    // logT('stiky', 'offset panel', -newPosition + 'px')
  };
  useScroll(onScroll);

  useEffect(() => {
    if (isMobile()) {
      return;
    }

    const updateScroll = () => {
      if (!stickyDisplay) return;
      onScroll(window.scrollX, window.scrollY);
    };
    if (!window.headerAffectedElement) window.headerAffectedElement = [];
    window.headerAffectedElement.push(updateScroll);
    return () => {
      if (window.headerAffectedElement?.length > 0) {
        const index = window.headerAffectedElement.indexOf(updateScroll);
        if (index >= 0) {
          window.headerAffectedElement.splice(index, 1);
        }
      }
    };
  }, [stickyDisplay]);

  useDidUpdateEffect(() => {
    if (
      !user?.settings?.visualRightPanelHide &&
      rightPanel.current &&
      stickyDisplay &&
      window.rightPanelPreviuslyDisabled
    ) {
      logT("rightPanel", "initial scroll after panel enable");
      window.rightPanelPreviuslyDisabled = false;
      rightPanel.current.style.top = `${topOffset.current}px`;
      onScroll(window.pageXOffset, window.pageYOffset);
    } else if (user?.settings?.visualRightPanelHide) {
      window.rightPanelPreviuslyDisabled = true;
    }
  }, [user]);

  if (user?.settings?.visualRightPanelHide) {
    logT("visual", "hide right panel because of settings");
    return null;
  }

  return (
    <div ref={rightPanel} className="rightBlocksPanel sticky">
      {stickyDisplay ? (
        <RecentPosts
          source="sticky"
          rightPanels={false}
          sticky={true}
          stickyShow={stickyDisplay}
          noFetch={true}
        />
      ) : null}
    </div>
  );
};
export default RightPanel;
