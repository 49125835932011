import React, { useEffect, useState, useRef } from "react";
import "./TabsSwitcher.css";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import useResize from "../global/UseResize";

export default ({ value, values, onChange, className, slow = false }) => {
  const tabsEl = useRef();
  const slider = useRef();
  const [selectValue, setSelectValue] = useState(value);
  const keys = Object.keys(values);

  useDidUpdateEffect(() => {
    setSelectValue(value);
  }, [value]);

  const recalcSelection = () => {
    if (slider?.current) {
      const selection = tabsEl.current.querySelectorAll(
        `[data-id="${selectValue}"]`,
      )?.[0];
      if (selection && slider?.current) {
        slider.current.style.left = selection.offsetLeft + "px";
        slider.current.style.top = selection.offsetTop + "px";
        slider.current.style.width = selection.offsetWidth + "px";
        slider.current.style.height = selection.offsetHeight + "px";
      }
    }
  };

  useEffect(() => {
    recalcSelection();
  }, [selectValue]);

  useResize((x) => {
    recalcSelection();
  });

  return (
    <div className={"tab-slider--nav" + (className ? ` ${className}` : "")}>
      <ul ref={tabsEl} className="tab-slider--tabs">
        {keys.map((key, i) => (
          <li
            key={i}
            data-id={key}
            className={
              "tab-slider--trigger" + (key == selectValue ? " active" : "")
            }
            onClick={() => {
              setSelectValue(key);
              if (onChange) {
                if (slow) setTimeout(() => onChange(key), 200);
                else onChange(key);
              }
            }}
          >
            {values[key]}
          </li>
        ))}
      </ul>
      {values[value] ? <div ref={slider} className="slider"></div> : null}
    </div>
  );
};
