import { htmlEscape } from "./Global";

const BBCODE_ALLOW_IMAGES = 1;

const bbParse = function (text, flags) {
  flags = flags || 0;
  let bbs = new Array();

  bbs["b"] = {};
  bbs["b"]["start"] = "<b>";
  bbs["b"]["end"] = "</b>";

  bbs["i"] = {};
  bbs["i"]["start"] = "<i>";
  bbs["i"]["end"] = "</i>";

  bbs["u"] = {};
  bbs["u"]["start"] = "<u>";
  bbs["u"]["end"] = "</u>";

  bbs["s"] = {};
  bbs["s"]["start"] = '<span style="text-decoration: line-through;">';
  bbs["s"]["end"] = "</span>";

  bbs["email"] = {};
  bbs["email"]["start"] = '<a href="mailto:$0">';
  bbs["email"]["end"] = "</a>";

  bbs["url"] = {};
  bbs["url"]["start_first"] = '<a href="';
  bbs["url"]["end_first"] = '">см. ссылку</a>';
  bbs["url"]["start_second"] = '<a href="$1">';
  bbs["url"]["end_second"] = "</a>";

  bbs["img"] = {};
  //alert(this.BBCODE_ALLOW_IMAGES);
  if (flags & BBCODE_ALLOW_IMAGES) {
    bbs["img"]["start"] = '<img src="';
    bbs["img"]["end"] = '" />';
  } else {
    bbs["img"]["start"] = '<a href="';
    bbs["img"]["end"] = '">(см. изображение)</a>';
  }

  bbs["quote"] = {};
  bbs["quote"]["start_first"] =
    '<div class="message-quote"><div class="message-quote-to">Цитата пользователя</div>';
  bbs["quote"]["end_first"] = "</div>";
  bbs["quote"]["start_second"] =
    '<div class="message-quote"><div class="message-quote-to">Цитата $1\'a</div>';
  bbs["quote"]["end_second"] = "</div>";

  bbs["spoiler"] = {};
  bbs["spoiler"]["start_first"] =
    '<div class="message-spoiler"><div class="message-spoiler-description">Скрытый текст</div><div class="message-spoiler-text">';
  bbs["spoiler"]["end_first"] = "</div></div>";
  bbs["spoiler"]["start_second"] =
    '<div class="message-spoiler"><div class="message-spoiler-description">$1</div><div class="message-spoiler-text">';
  bbs["spoiler"]["end_second"] = "</div></div>";

  bbs["color"] = {};
  bbs["color"]["start"] = '<font color="$1">';
  bbs["color"]["end"] = "</font>";

  bbs["center"] = {};
  bbs["center"]["start"] = '<div style="text-align: center;">';
  bbs["center"]["end"] = "</div>";

  bbs["left"] = {};
  bbs["left"]["start"] = '<div style="text-align: left;">';
  bbs["left"]["end"] = "</div>";

  bbs["right"] = {};
  bbs["right"]["start"] = '<div style="text-align: right;">';
  bbs["right"]["end"] = "</div>";

  bbs["size"] = {};
  bbs["size"]["start"] = '<font size="$1">';
  bbs["size"]["end"] = "</font>";

  bbs["font"] = {};
  bbs["font"]["start"] = '<font face="$1">';
  bbs["font"]["end"] = "</font>";

  bbs["attach"] = {};
  bbs["attach"]["start"] = '<a href="http://files.uruchie.org/download/';
  bbs["attach"]["end"] = '">$0</a>';

  bbs["video"] = {};
  bbs["video"]["start"] =
    '<iframe class="youtube-player" type="text/html" width="320" height="240" src="http://www.youtube.com/embed/$2" frameborder="0">';
  bbs["video"]["end"] = "</iframe>";

  //bbs["play_video"] = {};
  //bbs["play_video"]["start"] = "<div id=\"$0\">Uruchie Flash Player</div><script type=\"text/javascript\">var so = new SWFObject('players/video_player.swf','mpl','640','480','9'); so.addParam('allowscriptaccess','always'); so.addParam('allowfullscreen','true'); so.addParam('flashvars','&file=$0'); so.write('player_id_"
  //bbs["play_video"]["end"] = "');</script>";
  bbs["play_video"] = {};
  bbs["play_video"]["start"] = '<a href="';
  bbs["play_video"]["end"] = '">Скачать видео</a>';

  // bug help :)
  bbs["bug"] = {};
  bbs["bug"]["start"] = '<a href="http://bugs.uruchie.org/issues/$0">#';
  bbs["bug"]["end"] = "</a>";

  bbs["cbug"] = {};
  bbs["cbug"]["start"] =
    '<span style="text-decoration: line-through;"><a href="http://bugs.uruchie.org/issues/$0">#';
  bbs["cbug"]["end"] = "</a></span>";

  bbs["hr"] = {};
  bbs["hr"]["start"] = "<hr />";
  bbs["hr"]["end"] = "";

  bbs["tr"] = {};
  bbs["tr"]["start"] = "<tr>";
  bbs["tr"]["end"] = "</tr>";

  bbs["td"] = {};
  bbs["td"]["start"] = "<td>";
  bbs["td"]["end"] = "</td>";

  bbs["table"] = {};
  bbs["table"]["start"] = "<table>";
  bbs["table"]["end"] = "</table>";

  bbs["code"] = {};
  bbs["code"]["start"] = "<pre>";
  bbs["code"]["end"] = "</pre>";

  bbs["php"] = {};
  bbs["php"]["start"] = '<pre class="brush: php;">';
  bbs["php"]["end"] = "</pre>";

  bbs["html"] = {};
  bbs["html"]["start"] = '<pre class="brush: html;">';
  bbs["html"]["end"] = "</pre>";

  // clear hook
  bbs["noparse"] = {};

  let regExp = new RegExp(
    "(?:\\[)(?!/)([A-Za-z0-9_]+)(?:\\])|(?:\\[)(?!/)([A-Za-z0-9_]+)(?:=)(?:(?:(?:\"|'))([^\"']+)(?:(?:\"|')\\])|([^\\]]+)(?:\\]))",
  );
  let regParse = regExp.exec(text);

  // some debug
  //alert(regParse);

  let finalString = ""; // генерируется в процессе

  let bbCode;
  while (regParse != null) {
    if (regParse[1] != null) bbCode = regParse[1].toLowerCase();
    else if (regParse[2] != null) bbCode = regParse[2].toLowerCase();

    // нулевой ббкод или неверный ббкод, чистим и продолжаем работу
    if (bbCode == null) {
      text = text.replace(regExp, "");
      regParse = regExp.exec(text);
      continue;
    }

    var startPosition = text.search(regExp) + regParse[0].length;

    // если нету ббкода, вырезаем этот кусок и запоминаем (чтобы не повторялся)

    if (bbs[bbCode] == null) {
      finalString += text.substring(0, startPosition);
      text = text.substring(startPosition, text.length);
      regParse = regExp.exec(text);
      continue;
    }

    var findInternalParamEnd = text.search(
      new RegExp("\\[/" + bbCode + "\\]", "i"),
    );
    // Нету закрывающего кода
    if (findInternalParamEnd == -1) {
      finalString += text.substring(0, startPosition);
      text = text.substring(startPosition, text.length);
      regParse = regExp.exec(text);
      continue;
    }

    var internalParam = text.substr(
      startPosition,
      findInternalParamEnd - startPosition,
    );

    // [noparse]
    if (bbCode == "noparse") {
      // очень похожие дейсвия что и с вырезанием куска
      finalString += text.substring(startPosition, findInternalParamEnd); // 3 = [/]
      text = text.substring(
        findInternalParamEnd + bbCode.length + 3,
        text.length,
      );
      regParse = regExp.exec(text);
      continue;
    }

    if (bbs[bbCode] != null) {
      if (regParse[3] == null && regParse[4] == null) {
        if (
          bbs[bbCode]["start_first"] != null &&
          bbs[bbCode]["end_first"] != null
        ) {
          text = text.replace(
            regExp,
            bbParamRepalce(bbs[bbCode]["start_first"], [internalParam]),
          );
          text = text.replace(
            new RegExp("\\[/" + bbCode + "\\]", "i"),
            bbParamRepalce(bbs[bbCode]["end_first"], [internalParam]),
          );
        } else {
          text = text.replace(
            regExp,
            bbParamRepalce(bbs[bbCode]["start"], [internalParam]),
          );
          text = text.replace(
            new RegExp("\\[/" + bbCode + "\\]", "i"),
            bbParamRepalce(bbs[bbCode]["end"], [internalParam]),
          );
        }
      } else {
        if (regParse[3] == null) regParse[3] = regParse[4];

        // добавляем $0 = iternalParam и создаем массив
        var paramArray = regParse[3].split(";");
        paramArray.unshift(internalParam);

        if (
          bbs[bbCode]["start_second"] != null &&
          bbs[bbCode]["end_second"] != null
        ) {
          text = text.replace(
            regExp,
            bbParamRepalce(bbs[bbCode]["start_second"], paramArray),
          );
          text = text.replace(
            new RegExp("\\[/" + bbCode + "\\]", "i"),
            bbParamRepalce(bbs[bbCode]["end_second"], paramArray),
          );
        } else {
          text = text.replace(
            regExp,
            bbParamRepalce(bbs[bbCode]["start"], paramArray),
          );
          text = text.replace(
            new RegExp("\\[/" + bbCode + "\\]", "i"),
            bbParamRepalce(bbs[bbCode]["end"], paramArray),
          );
        }
      }
    } else {
      text = text.replace(regExp, "");
      text = text.replace(new RegExp("\\[/" + bbCode + "\\]", "i"), "");
    }

    regParse = regExp.exec(text);
  }
  //добавлем последний кусок
  finalString += text;
  return finalString;
};

const bbParamRepalce = function (string, array) {
  // some debug
  //alert(array);

  var regExp = RegExp("(?:\\$)([\\d]+)");
  var parse;
  while ((parse = regExp.exec(string)) != null) {
    var replaceValue = array[parseInt(parse[1])];
    if (replaceValue != null)
      string = string.replace(regExp, htmlEscape(replaceValue));
    else string = string.replace(regExp, "[unknown]");
  }
  return string;
};

const limitBBcode = function (string, limit) {
  var num = limit;

  if (string.length <= limit) return string;

  var tmpString = string;
  var regExp = new RegExp(
    "(?:\\[)(?!/)([A-Za-z0-9_]+)(?:\\])|(?:\\[)(?!/)([A-Za-z0-9_]+)(?:=)(?:(?:(?:\"|'))([^\"']+)(?:(?:\"|')\\])|([^\\]]+)(?:\\]))",
  );
  var regParse = regExp.exec(tmpString);

  var prevEnd = 0;
  let bbCode;
  let j;
  while (regParse != null) {
    if (regParse[1] != null) bbCode = regParse[1].toLowerCase();
    else if (regParse[2] != null) bbCode = regParse[2].toLowerCase();

    // нулевой ббкод или неверный ббкод, чистим и продолжаем работу
    if (bbCode == null) {
      var clearString = "";
      for (j = 0; j < regParse[0].length; j++) clearString += " ";
      tmpString = tmpString.replace(regExp, clearString);
      regParse = regExp.exec(tmpString);
      continue;
    }

    var findStart = tmpString.search(regExp);
    var findEnd = tmpString.search(new RegExp("\\[/" + bbCode + "\\]", "i"));

    if (findEnd > -1) {
      var endStartTag = findStart + regParse[0].length;
      var endBBTag = findEnd + ("[/" + bbCode + "]").length;
      if (limit - 1 >= findStart && limit <= endBBTag) {
        if (findEnd - endStartTag <= limit) {
          if (endBBTag > num && findEnd > prevEnd) num = endBBTag;
        } else {
          if (findStart < num && findStart >= 0) num = findStart;
        }
      }

      prevEnd = findEnd;
    }

    var clearString = "";
    for (j = 0; j < regParse[0].length; j++) clearString += " ";
    tmpString = tmpString.replace(regExp, clearString);
    regParse = regExp.exec(tmpString);
  }

  return string.substring(0, num) + (num != string.length ? "..." : "");
};

export { bbParse, limitBBcode };
