import React, { useState, useEffect } from "react";
import RecentPosts from "./RecentPosts";

const Original = () => {
  useEffect(() => {
    document.title = `${__("Original")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="original"
          onlyOriginal={true}
          recentPopularSwitcher={true}
          noLimitation={true}
          leftRatingDisplay={true}
          displayOnlyPosts={true}
          periodSwitcher={true}
        />
      </div>
    </div>
  );
};
export default Original;
