import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { getCookie } from "../global/Global";
import RecentPosts from "./RecentPosts";
import UserCircle from "./UserCircle";

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState({});
  const [subscriptionsHidden, setSubscriptionsHidden] = useState(true);
  const [chooseOneClick, setChooseOneClick] = useState(false);
  const [mode, setMode] = useState(0);

  const fetchData = async () => {
    let index = 0;
    let limit = 5000; // 50 by default
    // if (full) {
    //   index = 0;
    //   limit = Math.max(50, users.length);
    // }
    const token = getCookie("token") || "";
    const userid = parseInt(getCookie("userid")) || 0;
    if (!userid || !token) return;

    const data = await window.TALKVIOAPI(
      "subscriptions",
      {
        token,
        userid,
        subscriptionsUsers: true,
        subscriptionsTags: true,
      },
      { tag: "subscriptions" },
    );
    if (!data) {
      logT("subscriptions", "no data responce");
      return;
    }
    data.users?.forEach((user) => (user.enabled = true));
    data.tags?.forEach((tag) => (tag.enabled = true));
    setSubscriptions(data);
  };

  useEffect(() => {
    fetchData();
    document.title = `${__("Subscriptions")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="subscriptions"
          onlySubscriptions={mode == 0}
          onlyTagSubscriptions={mode == 1}
          recentPopularSwitcher={true}
          periodSwitcher={true}
          noLimitation={true}
          leftRatingDisplay={true}
          origin="subscriptions"
          subscriptions={subscriptions?.users
            ?.filter((s) => s.enabled)
            .map((s) => s.userid)}
          subscriptionsTags={subscriptions?.tags
            ?.filter((s) => s.enabled)
            .map((s) => s.tagid)}
          customScrollData={{ subscriptions, subscriptionsHidden, mode }}
          onCustomScrollDataRestore={({
            subscriptions,
            subscriptionsHidden,
            mode,
            chooseOneClick,
          }) => {
            window.TALKVIOAPI_CANCEL("subscriptions");
            setMode(mode);
            setSubscriptions(subscriptions);
            setSubscriptionsHidden(subscriptionsHidden);
            setChooseOneClick(chooseOneClick);
          }}
          leftPanels={
            <div
              className={
                "subscriptions typicalBlock column flex" +
                (subscriptionsHidden ? " hidden" : "")
              }
            >
              <div
                onClick={() => setSubscriptionsHidden(!subscriptionsHidden)}
                className="subscriptionsShowButton clickButton"
              >
                {subscriptionsHidden
                  ? __("Show subscriptions")
                  : __("Hide subscriptions")}
              </div>
              <div
                onClick={() => setMode(mode == 0 ? 1 : 0)}
                className="oneClick clickButton"
              >
                {mode == 0 ? __("To tags") : __("To users")}
              </div>
              {mode == 0
                ? subscriptions?.users?.map((user) => (
                    <div
                      className="sub flex row center-column"
                      onClick={(e) => {
                        if (chooseOneClick) {
                          subscriptions.users.forEach(
                            (s) => (s.enabled = false),
                          );
                          user.enabled = true;
                        } else {
                          if (user.enabled) {
                            const enabledSubs = subscriptions.users.filter(
                              (s) => s.enabled,
                            );
                            if (
                              enabledSubs.length == 1 &&
                              enabledSubs[0].userid == user.userid
                            ) {
                              return;
                            }
                          }
                          user.enabled = !user.enabled;
                        }
                        setSubscriptions(Object.assign({}, subscriptions));
                      }}
                    >
                      <UserCircle
                        user={user}
                        enabled={user.enabled}
                        onClick={() => {}}
                      />
                      <span className={user.enabled ? "enabled" : "disabled"}>
                        {user.username}
                      </span>
                    </div>
                  ))
                : null}
              {mode == 1
                ? subscriptions?.tags?.map((tag) => (
                    <div
                      className="sub flex row center-column"
                      onClick={(e) => {
                        if (chooseOneClick) {
                          subscriptions.tags.forEach(
                            (s) => (s.enabled = false),
                          );
                          tag.enabled = true;
                        } else {
                          if (tag.enabled) {
                            const enabledSubs = subscriptions.tags.filter(
                              (s) => s.enabled,
                            );
                            if (
                              enabledSubs.length == 1 &&
                              enabledSubs[0].tagid == tag.tagid
                            ) {
                              return;
                            }
                          }
                          tag.enabled = !tag.enabled;
                        }
                        setSubscriptions(Object.assign({}, subscriptions));
                      }}
                    >
                      <div
                        className={
                          "tag " + (tag.enabled ? "enabled" : "disabled")
                        }
                      >
                        <Link
                          to={"/tag/" + tag.tagid}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          #{tag.tagtext}
                        </Link>
                      </div>
                    </div>
                  ))
                : null}
              <div
                onClick={() => setChooseOneClick(!chooseOneClick)}
                className="oneClick clickButton"
              >
                {!chooseOneClick
                  ? __("Unselect on click")
                  : __("Select only one on click")}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
export default Subscriptions;
