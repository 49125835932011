import React, { useEffect, useState } from "react";
import useDidUpdateEffect from "../global/DidUpdateEffect";

export default ({ value, values, onChange, className, placeholder }) => {
  const [selectValue, setSelectValue] = React.useState(value);

  useDidUpdateEffect(() => {
    setSelectValue(value);
  }, [value]);

  return (
    <div className={"select-box" + (className ? ` ${className}` : "")}>
      {placeholder ? <div className="placeholder">{placeholder}:</div> : null}
      <select
        value={selectValue}
        onChange={(event) => {
          const value = event.target.value;
          setSelectValue(value);
          if (onChange) onChange(value);
        }}
      >
        {Object.keys(values).map((key) => (
          <option value={key}>{values[key]}</option>
        ))}
      </select>
    </div>
  );
};
