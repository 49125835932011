import React, { useState, useEffect } from "react";
import RecentPosts from "./RecentPosts";

const DelayedPosts = () => {
  useEffect(() => {
    document.title = `${__("Delayed posts")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="delayedPosts"
          delayedPosts={true}
          recentPopularSwitcher={true}
          noLimitation={true}
          leftRatingDisplay={true}
          displayOnlyPosts={true}
        />
      </div>
    </div>
  );
};
export default DelayedPosts;
