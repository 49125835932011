import React, { createContext, useEffect, useState, useRef } from "react";
import { eraseCookie, getCookie, setCookie } from "../global/Global";

export const ThemeContext = createContext();

const Threme = ({ children }) => {
  const matchMedia = window.matchMedia
    ? window.matchMedia("(prefers-color-scheme: dark)")
    : null;
  const isDarkModePreferred = useRef(matchMedia?.matches);
  logT("theme", "browser dark prefered theme =", isDarkModePreferred.current);
  const [darkMode, setDarkMode] = useState(
    getCookie("darkmode") || isDarkModePreferred.current,
  );
  logT("theme", "dark mode =", darkMode);
  if (typeof window.darkMode == "undefined") {
    window.darkMode = darkMode;
  }

  useEffect(() => {
    if (!matchMedia) {
      return;
    }
    matchMedia.addEventListener("change", () => {
      const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
      isDarkModePreferred.current = matchMedia.matches;
      logT("theme", "browser dark theme change =", isDarkModePreferred.current);
      setDarkMode(getCookie("darkmode") || isDarkModePreferred.current);
    });
  }, []);

  const setDarkTheme = (dark = true) => {
    logT("theme", "manually change theme =", dark);
    window.darkMode = dark;
    setCookie("darkmode", dark, 30);
    setDarkMode(dark);
  };

  const resetDarkTheme = () => {
    logT("theme", "reset theme to default =", isDarkModePreferred.current);
    window.darkMode = isDarkModePreferred.current;
    eraseCookie("darkmode");
    setDarkMode(isDarkModePreferred.current);
  };

  useEffect(() => {
    if (darkMode) {
      document.body.style.background = "#161a1d";
    } else {
      document.body.style.background = "#eee";
    }
  }, [darkMode]);

  return (
    <ThemeContext.Provider
      value={{
        setDarkTheme,
        resetDarkTheme,
        darkMode,
      }}
    >
      <div className={"theme" + (darkMode ? " dark-mode" : "")}>{children}</div>
    </ThemeContext.Provider>
  );
};
export default Threme;
