import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { eraseCookie, getCookie } from "../global/Global";
import useOutsideClick from "../global/UseOutsideClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faComments,
  faRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faReadme } from "@fortawesome/free-brands-svg-icons";

const NotificationList = ({
  user,
  onNotificationClick,
  onMenuClick,
  onExitClick,
}) => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [enabledMore, setEnabledMore] = useState(true);
  const [notificationLoading, setNotificationLoading] = useState(true);
  const notificationRef = useRef();

  useOutsideClick(
    () => {
      if (onExitClick) onExitClick();
    },
    notificationRef,
    [],
  );

  const fetchData = async (full = false) => {
    let index = (notifications && notifications.length) || 0;
    let limit = 50;
    if (full) {
      index = 0;
      limit = Math.max(50, notifications.length);
    }
    const token = getCookie("token") || "";
    const userid = parseInt(getCookie("userid")) || 0;
    const data = await window.TALKVIOAPI("notifications", {
      index,
      limit,
      token,
      userid,
    });
    if (data.notifications?.length == 0) {
      setEnabledMore(false);
    }
    setNotificationLoading(false);
    if (!full)
      setNotifications((notifications || []).concat(data.notifications));
    else setNotifications(data.notifications);
  };

  window.TALKVIO_ON(
    "notification",
    (sub) => {
      if (sub.userid == user.userid) {
        fetchData(true);
      }
    },
    "notificationList",
  );

  window.TALKVIO_ON(
    "readNotification",
    (sub) => {
      if (sub.userid == user.userid) {
        fetchData(true);
      }
    },
    "notificationList",
  );

  window.TALKVIO_ON(
    "readAllNotifications",
    (sub) => {
      if (sub.userid == user.userid) {
        fetchData(true);
      }
    },
    "notificationList",
  );

  useEffect(() => {
    fetchData();
    window.blockHeader("notificationList");
    return () => {
      window.unblockHeader("notificationList");
    };
  }, []);

  const Notification = ({ notification }) => (
    <div
      className={"notification" + (notification.readed ? " readed" : "")}
      ref={notificationRef}
      onClick={() => {
        if (!notification.readed) {
          const token = getCookie("token") || "";
          const userid = parseInt(getCookie("userid")) || 0;
          window.TALKVIOAPI("readNotification", {
            token,
            userid,
            notificationid: notification.notificationid,
          });
        }
        if (onNotificationClick) onNotificationClick();
        if (notification.postid) navigate("/post/" + notification.postid);
      }}
    >
      {notification.postid && notification.type == 0 && (
        <span>
          <FontAwesomeIcon icon={faCircleInfo} />
          Вам пришел ответ в вашей теме "{notification.threadtitle}" от{" "}
          {notification.sourceusername}'a
        </span>
      )}
      {notification.postid && notification.type == 1 && (
        <span>
          <FontAwesomeIcon icon={faCircleInfo} />
          Ваше упоминание было в цитате "{notification.threadtitle}" от{" "}
          {notification.sourceusername}'a
        </span>
      )}
      {notification.postid && notification.type == 2 && (
        <span>
          <FontAwesomeIcon icon={faCircleInfo} />К вам обратились в "
          {notification.threadtitle}" от {notification.sourceusername}'a
        </span>
      )}
      {notification.postid && notification.type == 5 && (
        <span>
          <FontAwesomeIcon icon={faCircleInfo} />К вашей группе обратились "
          {notification.threadtitle}" от {notification.sourceusername}'a
        </span>
      )}
      {notification.postid && notification.type == 3 && (
        <span>
          <FontAwesomeIcon icon={faCircleInfo} />В подписанной вами теме "
          {notification.threadtitle}" новое сообщение от{" "}
          {notification.sourceusername}'a
        </span>
      )}
      {notification.postid && notification.type == 4 && (
        <span>
          <FontAwesomeIcon icon={faCircleInfo} />
          На ваш комментарий к посту "{notification.threadtitle}" ответили.
          Новое сообщение от {notification.sourceusername}'a
        </span>
      )}
      {!notification.postid && (
        <span>
          <FontAwesomeIcon icon={faCircleInfo} />К вам обратились в уже
          удаленном посте от {notification.sourceusername}'a
        </span>
      )}
      <span> ({moment(notification.dateline * 1000).fromNow()})</span>
    </div>
  );

  if (!notifications || notificationLoading) {
    return (
      <ul className="notificationList">
        <li>Загрузка нотификаций...</li>
      </ul>
    );
  }

  return (
    <ul className="notificationList header-related">
      <li
        className="notification profile"
        onClick={() => {
          if (onNotificationClick) onNotificationClick();
          navigate("/user/" + user.userid);
        }}
      >
        <FontAwesomeIcon icon={faUser} /> {__("Go to my profile")}
      </li>
      <li
        className="notification profile"
        onClick={() => {
          if (onNotificationClick) onNotificationClick();
          navigate("/answers");
        }}
      >
        <FontAwesomeIcon icon={faComments} /> {__("Go to answers")}
      </li>
      <li
        className="notification profile"
        onClick={(e) => {
          if (onNotificationClick) onNotificationClick();
          if (onMenuClick) onMenuClick();
          // fix menu close with outside click
          e.stopPropagation();
        }}
      >
        <FontAwesomeIcon icon={faRightFromBracket} /> {__("Menu")}
      </li>
      <li
        className="notification profile"
        onClick={() => {
          const token = getCookie("token") || "";
          const userid = parseInt(getCookie("userid")) || 0;
          window.TALKVIOAPI("readAllNotifications", {
            token,
            userid,
          });
          if (onNotificationClick) onNotificationClick();
        }}
      >
        <FontAwesomeIcon icon={faReadme} /> {__("Mark all as readed")}
      </li>
      {notifications.map((notification, i) => {
        let element = (
          <li key={i}>
            <Notification notification={notification} />
          </li>
        );
        if (i >= 49 && i == notifications.length - 1 && enabledMore) {
          element = (
            <>
              {element}
              <li
                className="notification more"
                onClick={(e) => {
                  fetchData();
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {__("More")}
              </li>
            </>
          );
        }
        return element;
      })}
    </ul>
  );
};
export default NotificationList;
