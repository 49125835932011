import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RecentPosts from "./RecentPosts";

const Section = () => {
  let { forumid } = useParams();

  useEffect(() => {
    document.title = `section - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="section"
          forumid={forumid}
          noLimitation={true}
          leftRatingDisplay={true}
          recentPopularSwitcher={true}
          periodSwitcher={true}
        />
      </div>
    </div>
  );
};
export default Section;
