const colors_codes = [
  "#5a404a",
  "#d4ec50",
  "#223a5a",
  "#af64d7",
  "#74538f",
  "#d8cb8b",
  "#a5db5a",
  "#5f2023",
  "#fca45c",
  "#3d269a",
  "#a0d412",
  "#c24005",
  "#f02d87",
  "#e0eb14",
  "#dffcc4",
  "#c39988",
  "#ff6639",
  "#32a73b",
  "#9fb300",
  "#78a8fb",
  "#cd3a5e",
  "#9c4d9c",
  "#cbc3c3",
  "#449531",
  "#509296",
  "#840f85",
  "#e500ac",
  "#3a3be8",
  "#037aaf",
  "#724eee",
  "#bff31b",
  "#b64ae7",
  "#338d6e",
  "#214fcf",
  "#b767c5",
  "#af2862",
  "#4fcba0",
  "#a95808",
  "#379865",
  "#e7f194",
  "#cabc16",
  "#58a199",
  "#d6a4c6",
  "#09022c",
  "#5936fb",
  "#31ef0d",
  "#7d063c",
  "#b2e5bc",
  "#ef440c",
  "#5e256d",
  "#46596d",
  "#7dd993",
  "#ae5f6f",
  "#20d21e",
  "#de8e51",
  "#d2325f",
  "#264b00",
  "#74a53f",
  "#a02dda",
  "#48c219",
  "#963fe4",
  "#d80e4e",
  "#206479",
  "#90223d",
  "#70bb93",
  "#48d623",
  "#315d6d",
  "#fedb6b",
  "#40746d",
  "#96deb0",
  "#8734d6",
  "#e916e7",
  "#64a207",
  "#21283c",
  "#041db8",
  "#c7cb5c",
  "#c5a2f8",
  "#9a6f9b",
  "#562b07",
  "#92e992",
  "#2fbe3d",
  "#e3cadf",
  "#5c9dae",
  "#a6586f",
  "#9aede3",
  "#bb9f9f",
  "#7a3c03",
  "#1fa945",
  "#f0927e",
  "#b47b5f",
  "#a65e0b",
  "#1b6593",
  "#8f2a2a",
  "#94d846",
  "#403f67",
  "#ec11e6",
  "#bb52f9",
  "#a9da55",
  "#6736b4",
  "#37fa33",
  "#44b704",
  "#9d455e",
  "#441a38",
  "#70928e",
  "#d995b3",
  "#035277",
  "#dab488",
  "#1c2825",
  "#4c64fd",
  "#817068",
  "#7c2525",
  "#aafa64",
  "#73c0fe",
  "#168280",
  "#206224",
  "#5393c2",
  "#4a1c91",
  "#98353d",
  "#3a5676",
  "#17bd42",
  "#0c8f77",
  "#f942ad",
  "#bed225",
  "#cd42e0",
  "#8051ad",
  "#86f5f6",
  "#d17e42",
  "#80ba90",
  "#98de8e",
  "#bdfcfc",
  "#989267",
  "#b1348b",
  "#9f8ac3",
  "#72c5a3",
  "#0a846c",
  "#be345a",
  "#41732a",
  "#b27230",
  "#5aaff8",
  "#07c265",
  "#b21541",
  "#46d335",
  "#f1d98a",
  "#c921a0",
  "#dbc47f",
  "#225521",
  "#0109cc",
  "#c17083",
  "#b4905e",
  "#f3a4d9",
  "#69a613",
  "#db533b",
  "#0c9c3d",
  "#715e18",
  "#8e261e",
  "#00cbb5",
  "#11f61f",
  "#3d6a30",
  "#eaab5a",
  "#d92c6b",
  "#1efecc",
  "#e92a0f",
  "#00a9b4",
  "#fb588a",
  "#89d20b",
  "#d597f3",
  "#73c0c8",
  "#914475",
  "#03ebcd",
  "#6deae6",
  "#cb7ca1",
  "#9f4727",
  "#abf162",
  "#03b1eb",
  "#371507",
  "#211d17",
  "#372a6a",
  "#0e43e9",
  "#fa3953",
  "#37ec47",
  "#9f65cd",
  "#0a4e3b",
  "#7f7e28",
  "#b5aa47",
  "#ce01a4",
  "#007a99",
  "#65d977",
  "#8f8645",
  "#11a83f",
  "#a16b4a",
  "#de464e",
  "#0447ba",
  "#49a9e5",
  "#2f6bc9",
  "#743b0d",
  "#32cf00",
  "#2c727b",
  "#a53927",
  "#11875b",
  "#65809c",
  "#a8ccc8",
  "#b86d06",
  "#fc50f0",
  "#d1500d",
  "#0a39ef",
  "#777d36",
  "#c5c4fb",
  "#72f0b4",
  "#34643d",
  "#fd0668",
  "#ff58cc",
  "#00f02f",
  "#ff2766",
  "#24a5bb",
  "#ac1bfd",
  "#373a02",
  "#76508a",
  "#08c31d",
  "#771e45",
  "#daa9d1",
  "#2e13e6",
  "#f57368",
  "#93375a",
  "#4089f1",
  "#734d0f",
  "#3456b4",
  "#1bb3ad",
  "#a029f0",
  "#f6341d",
  "#dc144c",
  "#baacc5",
  "#491052",
  "#2d5969",
  "#f729ff",
  "#fbc4ec",
  "#546a01",
  "#3177aa",
  "#c3fed2",
  "#7f60bc",
  "#900910",
  "#3461c6",
  "#2dcfe2",
  "#324154",
  "#fc6ecc",
  "#c65c82",
  "#ac3754",
  "#f56ec3",
  "#18bebb",
  "#f8de3e",
  "#42214a",
  "#ff1091",
  "#4f8bab",
  "#fd6381",
  "#8f4151",
  "#78452d",
  "#ecc26d",
];

const stringHashCode = (str) => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const logT = (type, ...d) => {
  if (!window.enableLog && !window.screenLogs && !window.networkLogs) {
    return;
  }
  const now = new Date();
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();
  const ms = now.getMilliseconds().toString().padStart(3, "0");
  if (window.screenLogs) {
    let logElement = document.getElementById("lgses");
    if (logElement) {
      let newParagraph = document.createElement("p");
      let textNode = document.createTextNode(
        `[${date} ${time}.${ms}] [${type}] ${d
          .map((o) => {
            let str = JSON.stringify(o);
            if (str && str.length > 512) str = str.slice(0, 512) + " ...";
            return str;
          })
          .join(" ")}`,
      );
      newParagraph.appendChild(textNode);
      logElement.prepend(newParagraph);
      let logsRecords = logElement.getElementsByTagName("p");
      if (logsRecords.length > 10) {
        logElement.removeChild(logsRecords[logsRecords.length - 1]);
      }
    }
  } else if (window.networkLogs) {
    window.TALKVIOAPI("logNetwork", {
      level: "verbose",
      log: `[${date} ${time}.${ms}] [${type}] ${d
        .map((o) => {
          let str = JSON.stringify(o);
          if (str && str.length > 1024) str = str.slice(0, 1024) + " ...";
          return str;
        })
        .join(" ")}`,
      username: window.userName,
    });
  } else {
    const needColor = !(typeof window.TalkvioAndroid != "undefined");
    if (needColor) {
      console.log(
        `%c[${date} ${time}.${ms}] [${type}]%c`,
        `color: ${colors_codes[Math.abs(stringHashCode(type)) % 256]}`,
        "",
        ...d,
      );
    } else {
      console.log(`[${date} ${time}.${ms}] [${type}]`, ...d);
    }
  }
};

const logTW = (type, ...d) => {
  if (!window.enableLog && !window.screenLogs && !window.networkLogs) {
    return;
  }
  const now = new Date();
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();
  const ms = now.getMilliseconds().toString().padStart(3, "0");
  if (window.screenLogs) {
    let logElement = document.getElementById("lgses");
    if (logElement) {
      let newParagraph = document.createElement("p");
      let textNode = document.createTextNode(
        `[${date} ${time}.${ms}] [WARN] [${type}] ${d
          .map((o) => {
            let str = JSON.stringify(o);
            if (str && str.length > 512) str = str.slice(0, 512) + " ...";
            return str;
          })
          .join(" ")}`,
      );
      newParagraph.appendChild(textNode);
      logElement.prepend(newParagraph);
      let logsRecords = logElement.getElementsByTagName("p");
      if (logsRecords.length > 10) {
        logElement.removeChild(logsRecords[logsRecords.length - 1]);
      }
    }
  } else if (window.networkLogs) {
    window.TALKVIOAPI("logNetwork", {
      level: "warning",
      log: `[${date} ${time}.${ms}] [${type}] ${d
        .map((o) => {
          let str = JSON.stringify(o);
          if (str && str.length > 1024) str = str.slice(0, 1024) + " ...";
          return str;
        })
        .join(" ")}`,
      username: window.userName,
    });
  } else {
    const needColor = !(typeof window.TalkvioAndroid != "undefined");
    if (needColor) {
      console.warn(
        `%c[${date} ${time}.${ms}] [${type}]%c`,
        `color: ${colors_codes[Math.abs(stringHashCode(type)) % 256]}`,
        "",
        ...d,
      );
    } else {
      console.warn(`[${date} ${time}.${ms}] [${type}]`, ...d);
    }
  }
};

const logTE = (type, ...d) => {
  if (!window.enableLog && !window.screenLogs && !window.networkLogs) {
    return;
  }
  const now = new Date();
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();
  const ms = now.getMilliseconds().toString().padStart(3, "0");
  if (window.screenLogs) {
    let logElement = document.getElementById("lgses");
    if (logElement) {
      let newParagraph = document.createElement("p");
      let textNode = document.createTextNode(
        `[${date} ${time}.${ms}] [ERROR] [${type}] ${d
          .map((o) => {
            let str = JSON.stringify(o);
            if (str && str.length > 512) str = str.slice(0, 512) + " ...";
            return str;
          })
          .join(" ")}`,
      );
      newParagraph.appendChild(textNode);
      logElement.prepend(newParagraph);
      let logsRecords = logElement.getElementsByTagName("p");
      if (logsRecords.length > 10) {
        logElement.removeChild(logsRecords[logsRecords.length - 1]);
      }
    }
  } else if (window.networkLogs) {
    window.TALKVIOAPI("logNetwork", {
      level: "error",
      log: `[${date} ${time}.${ms}] [${type}] ${d
        .map((o) => {
          let str = JSON.stringify(o);
          if (str && str.length > 1024) str = str.slice(0, 1024) + " ...";
          return str;
        })
        .join(" ")}`,
      username: window.userName,
    });
  } else {
    const needColor = !(typeof window.TalkvioAndroid != "undefined");
    if (needColor) {
      console.error(
        `%c[${date} ${time}.${ms}] [${type}]%c`,
        `color: ${colors_codes[Math.abs(stringHashCode(type)) % 256]}`,
        "",
        ...d,
      );
    } else {
      console.error(`[${date} ${time}.${ms}] [${type}]`, ...d);
    }
  }
};

window.onerror = function (message, source, lineno, colno, error) {
  window.TALKVIOAPI("logNetwork", {
    level: "error",
    log: `${message} ${source} [${lineno}:${colno}] stack: ${error?.stack}`,
    username: window.userName,
  });
  return false; // Let the browser continue its default error handling
};

window.addEventListener("unhandledrejection", function (event) {
  window.TALKVIOAPI("logNetwork", {
    level: "error",
    log: `Unhandled Promise rejection: ${event.reason}`,
    username: window.userName,
  });
});

export { logT, logTW, logTE };
