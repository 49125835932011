import React from "react";
import { Link } from "react-router-dom";
import DefaultAvatar_150 from "../default-avatar_150.png";
import { transliterateLink } from "../global/Global";

const Forum = ({ forum }) => {
  // forum.forums.map(subForum => <Forum forum={subForum} key={subForum.forumid} />)

  switch (forum.level) {
    case 0:
      return (
        <ul className="forumlist">
          <li className="title">{forum.title}</li>
          {forum.forums.map((subForum) => (
            <Forum forum={subForum} key={subForum.forumid} />
          ))}
        </ul>
      );
    case 1:
      return (
        <li className="forum level1">
          <div className="titles">
            {/* <img src="/images/statusicon/stats_buttons/11/forum_old.png" alt="some" /> */}
            <div className="title">
              <Link to={"/forums/" + forum.forumid}>{forum.title}</Link>
            </div>
            <div className="description">{forum.description}</div>
            {forum.forums.map((subForum) => (
              <Forum forum={subForum} key={subForum.forumid} />
            ))}
          </div>
          <div className="statistic">
            <div className="threadscount">
              {__("Threads")}: {forum.threadcount}
            </div>
            <div className="replycount">
              {__("Messages")}: {forum.replycount}
            </div>
          </div>
          <div className="lastpost">
            <div className="smallavatar">
              <img
                src={
                  forum.lastposteravrev > 0
                    ? `https://talkvio.com/customavatars/avatar${forum.lastposterid}_${forum.lastposteravrev}.gif`
                    : DefaultAvatar_150
                }
                alt={__("Avatar of user") + " " + forum.lastposter}
                width="32"
              />
            </div>
            <div className="lastposttitle">
              <Link
                to={
                  "/threads/" +
                  forum.lastthreadid +
                  `-${transliterateLink(forum.lastthread)}`
                }
              >
                {forum.lastthread}
              </Link>
            </div>
            <div className="lastpostuser">
              {__("by")}{" "}
              <Link to={"/user/" + forum.lastposterid}>{forum.lastposter}</Link>
            </div>
          </div>
        </li>
      );
    case 2:
    default:
      return (
        <ul className="subforumslist">
          <li>
            <Link to={"/forums/" + forum.forumid}>{forum.title}</Link>
          </li>
        </ul>
      );
  }
};
export default Forum;
