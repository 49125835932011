const fileData = (
  file,
  files_match = "image.*",
  limit_size = 25 * 1024 * 1024,
) =>
  new Promise((resolve) => {
    if (!(file instanceof File) && !(file instanceof Blob)) {
      resolve(file);
      return;
    }

    if (!file) {
      resolve();
      return;
    }

    if (!file.type.match(files_match)) {
      window.showError(__("Error"), __("Its not a image"));
      resolve();
      return;
    }

    if (file.size > limit_size) {
      window.showError(
        __("Error"),
        __("Max limit size for images") +
          " 25mb. " +
          __("Please reduce image size and try again."),
      );
      logT("fileData", "limitation reached", file.size, ">", limit_size);
      resolve();
      return;
    }

    var reader = new FileReader();
    reader.onload = function (e) {
      resolve(e.target.result);
    };
    reader.onerror = function (e) {
      window.showError(
        __("Error"),
        __("Error reading file") + ". " + __("Please, repeat") + ".",
      );
      logTE(
        "fileData",
        "error reading file",
        file,
        "error",
        JSON.stringify(e, ["message", "arguments", "type", "name"]),
      );
      resolve();
    };
    reader.readAsDataURL(file);
  });

const imageData = (file) => fileData(file, "image.*", 25 * 1024 * 1024);
const videoData = (file) => fileData(file, "video.*", 200 * 1024 * 1024);

export { imageData, videoData };

function dataURItoBlob(dataURI) {
  if (dataURI instanceof File || dataURI instanceof Blob) {
    return dataURI;
  }

  // convert base64 to raw binary data held in a string
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var arrayBuffer = new ArrayBuffer(byteString.length);
  var _ia = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  var dataView = new DataView(arrayBuffer);
  var blob = new Blob([dataView], { type: mimeString });
  return blob;
}

export { dataURItoBlob };
