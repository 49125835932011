import React, { useState, useEffect, useRef } from "react";
import RecentPosts from "./RecentPosts";

const Bookmarks = () => {
  useEffect(() => {
    document.title = `${__("Bookmarks")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="bookmarks"
          bookmarks={true}
          recentPopularSwitcher={true}
          noLimitation={true}
          leftRatingDisplay={true}
        />
      </div>
    </div>
  );
};
export default Bookmarks;
