import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useEffect } from "react";
import { isMobile } from "../global/Global";

export default ({ onFiles, children, width, height }) => {
  const inputEl = useRef(null);
  const blockEl = useRef(null);

  const processFiles = (files) => {
    if (onFiles) onFiles(files);
  };

  const dragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy"; // Explicitly show this is a copy.
  };

  const drop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    processFiles(event.dataTransfer.files); // FileList object.
  };

  useEffect(() => {
    const input = blockEl.current;
    input.addEventListener("dragover", dragOver, false);
    input.addEventListener("drop", drop, false);
    return () => {
      input.removeEventListener("dragover", dragOver);
      input.removeEventListener("dragover", drop);
    };
  }, []);

  return (
    <div
      style={{ cursor: "pointer" }}
      ref={blockEl}
      onClick={() => {
        inputEl.current.click();
      }}
    >
      {children ? (
        <video controls={true}>
          <source src={children} />
        </video>
      ) : (
        <div className="videoUpload">
          <div className="videoIcon">
            <FontAwesomeIcon icon={faVideo} />
          </div>
          <div className="imageText">
            {isMobile()
              ? __("Please click here to upload the video")
              : __(
                  "Please drag and drop video here or click here to upload it",
                )}
          </div>
        </div>
      )}
      <input
        ref={inputEl}
        onChange={async (e) => {
          let node = e.currentTarget;
          let files = node.files;
          if (typeof window.TalkvioAndroid != "undefined" && files.length > 0) {
            files = await Promise.all(
              Array.from(files).map(async (file, index) => {
                logT("android", "cloning file for future use", index, file);
                try {
                  const buffer = await file.arrayBuffer();
                  const cloneFile = new File([buffer], file.name, {
                    type: file.type,
                  });
                  return cloneFile;
                } catch (e) {
                  logTE("android", "error clonning file", file);
                  return null;
                }
              }),
            );
          }
          processFiles(files);
          node.value = null;
        }}
        type="file"
        accept="video/*"
        style={{ display: "none" }}
      />
    </div>
  );
};
