import { useRef } from "react";
import useResize from "../global/UseResize";

const RelativeContainer = ({ children }) => {
  const offsetX = useRef(0);
  const offsetY = useRef(0);
  const relativeContainer = useRef();

  useResize((x, y) => {
    if (!relativeContainer?.current) return;

    const rect = relativeContainer.current.getBoundingClientRect();

    const offX = x - (rect.left + rect.width) + offsetX.current - 20;
    const offY = y - (rect.top + rect.height) + offsetY.current - 10;

    if (offX < 0 || rect.left < 0 || offX - offsetX.current < 0)
      offsetX.current = offX;
    if (offY < 0 || rect.top < 0 || offY - offsetY.current < 0)
      offsetY.current = offY;

    logT(
      "relative",
      "relative container location change",
      `${offX | 0}x${offY | 0}`,
      `l: ${offX - offsetX.current}x${offY - offsetY.current}`,
      `w: ${x}x${y}`,
      `rect: ${rect.left | 0}x${rect.top | 0}x${rect.width | 0}x${rect.height | 0}`,
    );

    relativeContainer.current.style.transform = `translate(${offsetX.current}px, ${offsetY.current}px)`;
  });

  return (
    <div className="relativeContainer" ref={relativeContainer}>
      {children}
    </div>
  );
};

export default RelativeContainer;
