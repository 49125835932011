import React, { useEffect, useState, useRef } from "react";
import useOutsideClick from "../global/UseOutsideClick";
import useResize from "../global/UseResize";

const MenuList = ({ menuList, items, setOpenMenu }) => {
  const offsetX = useRef(0);
  const offsetY = useRef(0);
  const [menu, setMenu] = useState(items || []);

  useResize((x, y) => {
    if (!menuList?.current) return;

    const rect = menuList.current.getBoundingClientRect();

    const offX = x - (rect.left + rect.width) + offsetX.current - 20;
    const offY = y - (rect.top + rect.height) + offsetY.current - 10;

    if (offX < 0) offsetX.current = offX;
    if (offY < 0) offsetY.current = offY;

    logT(
      "menu",
      "menu location change",
      `${offX | 0}x${offY | 0}`,
      `w: ${x}x${y}`,
      `rect: ${rect.left | 0}x${rect.top | 0}x${rect.width | 0}x${rect.height | 0}`,
    );

    menuList.current.style.transform = `translate(${offsetX.current}px, ${offsetY.current}px)`;
  });

  const renderMenu = () => {
    return (
      <>
        {menu
          ? menu
              .filter((n) => n)
              .map((item, i) => (
                <div
                  key={i}
                  className="menu-item"
                  onClick={() => {
                    if (item.click) item.click(() => setOpenMenu(false));
                    else if (item.items) setMenu(item.items);
                  }}
                >
                  {item.name}
                </div>
              ))
          : null}
      </>
    );
  };

  return (
    <div className="menu-list" ref={menuList}>
      {renderMenu()}
    </div>
  );
};

export default ({ children, className, items }) => {
  const contextMenu = useRef();
  const menuList = useRef();
  const [menuOpen, setOpenMenu] = useState(!children);

  useOutsideClick(
    (target) => {
      if (!menuOpen) return;
      if (contextMenu?.current.contains(target)) return;
      if (target.classList.contains("menu-item")) return;

      logT("menu", "close menu because click outside");
      setOpenMenu(false);
    },
    menuList,
    [menuOpen],
    () => menuOpen,
  );

  return (
    <div
      className={"context-menu" + (className ? ` ${className}` : "")}
      ref={contextMenu}
    >
      {children ? (
        <div className="menu-caller" onClick={() => setOpenMenu(!menuOpen)}>
          {children}
        </div>
      ) : null}
      {menuOpen ? (
        <MenuList items={items} menuList={menuList} setOpenMenu={setOpenMenu} />
      ) : null}
    </div>
  );
};
