import React from "react";
import { Link } from "react-router-dom";
import DefaultAvatar_150 from "../default-avatar_150.png";
import { getCookie } from "../global/Global";

export default ({ user, onClick, enabled }) => {
  return (
    <div className="userCircle">
      <div className="avatar">
        <Link
          to={"/user/" + user.userid}
          onClick={(e) => {
            if (onClick) {
              e.preventDefault();
              onClick();
            }
          }}
        >
          <img
            src={
              user.avatarrevision > 0
                ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                : DefaultAvatar_150
            }
            className={
              "circle" +
              (typeof enabled != "undefined"
                ? enabled
                  ? " enabled"
                  : " disabled"
                : "")
            }
            alt={"Аватар пользователя " + user.username}
          />
        </Link>
      </div>
    </div>
  );
};
