import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import DefaultAvatar_150 from "../default-avatar_150.png";
import { getCookie } from "../global/Global";
import Tooltip from "./Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faUser, faVenus } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";

const UserBlock = ({ user, myUser }) => {
  return (
    <li className="userBlock">
      <div className="username">
        <Link to={"/user/" + user.userid}>
          {user.username}
          <sup className="gender">
            {user.gender == "male" ? (
              <FontAwesomeIcon className="male" icon={faMars} />
            ) : null}
            {user.gender == "female" ? (
              <FontAwesomeIcon className="female" icon={faVenus} />
            ) : null}
          </sup>
        </Link>
      </div>
      <div className="avatar">
        <Link to={"/user/" + user.userid}>
          <img
            src={
              user.avatarrevision > 0
                ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                : DefaultAvatar_150
            }
            alt={"Аватар пользователя " + user.username}
          />
        </Link>
      </div>
      {myUser?.userid > 0 && user.userid != myUser?.userid ? (
        <div
          className={
            "clickButton subscriptionButtonBlock" +
            (user.subscriptionid > 0 ? " unsubscribe" : " subscribe")
          }
          style={{ cursor: "pointer" }}
          onClick={async () => {
            const token = getCookie("token");
            const userid = parseInt(getCookie("userid"));
            const data = await window.TALKVIOAPI("subscribe", {
              userid,
              token,
              targetuserid: user.userid,
            });
            window.displayError(data);
          }}
        >
          {user.subscriptionid > 0 ? __("Unsubscribe") : __("Subscribe")}
        </div>
      ) : null}
      <div className="registation">
        {__("Registered since")}{" "}
        {moment(user.joindate * 1000).format("MMMM Do YYYY")} (
        {moment(user.joindate * 1000).fromNow()})
      </div>
      <div className="lastactivity">
        {__("Last activity")}: {moment(user.lastactivity * 1000).fromNow()}
      </div>
      <div className="infoblock">
        <div className="info-element posts">
          <div className="value">{user.posts}</div>
          <div className="desc">{__("Records")}</div>
        </div>
        <div className="info-element reputation">
          <div className="value">{user.reputation}</div>
          <div className="desc">{__("Reputation")}</div>
        </div>
        <Tooltip
          hint={__(
            "Karma is displaying how much other users approves or like this user or user actions. Only registred people with karma > 5 itself can change this value.",
          )}
        >
          <div className="info-element karma">
            <div className="value">
              <FontAwesomeIcon icon={faHeart} />
              <span>{user.karma}</span>
            </div>
            <div className="desc">{__("Karma")}</div>
          </div>
        </Tooltip>
        <div className="info-element subscribers">
          <div className="value">
            <FontAwesomeIcon icon={faUser} />
            <span>{user.subscribers}</span>
          </div>
          <div className="desc">{__("Subscribers")}</div>
        </div>
      </div>
    </li>
  );
};
export default UserBlock;
