import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import DefaultAvatar_150 from "../default-avatar_150.png";
import { transliterateLink } from "../global/Global";

const Thread = ({ thread }) => {
  return (
    <li className="thread">
      <div className="threadinfo">
        <div className="title">
          <div className="threadstatus threadstatusstyleTEMPORARY"></div>
          <Link
            to={
              "/threads/" +
              thread.threadid +
              `-${transliterateLink(thread.title)}`
            }
          >
            {thread.title}
          </Link>
        </div>
        <div className="poster">
          <Link to={"/user/" + thread.postuserid}>{thread.postusername}</Link>,{" "}
          {moment(thread.dateline * 1000).format("MMMM Do YYYY, h:mm:ss a")}
        </div>
      </div>
      <div className="statistic">
        <div className="replycount">
          {__("Replies")}: {thread.replycount}
        </div>
        <div className="views">
          {__("Views")}: {thread.views}
        </div>
      </div>
      <div className="lastpostinfo">
        <div className="lastposter">
          <Link to={"/post/" + thread.lastpostid}>
            <img
              src={
                thread.lastposteravrev > 0
                  ? `https://talkvio.com/customavatars/avatar${thread.lastposterid}_${thread.lastposteravrev}.gif`
                  : DefaultAvatar_150
              }
              alt={"Аватар пользователя " + thread.lastposter}
              width="32"
            />
          </Link>
          <Link to={"/user/" + thread.lastposterid}>{thread.lastposter}</Link>
        </div>
        <div className="lastposttime">
          {moment(thread.lastposttime * 1000).format("MMMM Do YYYY, h:mm:ss a")}
        </div>
      </div>
    </li>
  );
};
export default Thread;
