import { useEffect } from "react";

const useScroll = (callback, condition, element) => {
  useEffect(() => {
    if (condition && !condition()) return;

    const unsubscribe = () => {
      (element || window).removeEventListener("scroll", handleScroll);
    };

    const handleScroll = () => {
      // Your logic here, you can pass additional information to the callback if needed
      callback(window.pageXOffset, window.pageYOffset, unsubscribe);
    };

    // Attach the event listener
    (element || window).addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      (element || window).removeEventListener("scroll", handleScroll);
    };
  }, [callback]);
};

export default useScroll;
