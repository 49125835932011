import { useRef } from "react";
import useOutsideClick from "../global/UseOutsideClick";

const ClickOutsideContainer = ({ children, onClick, isShow, setIsShow }) => {
  const clickContainer = useRef();

  useOutsideClick(
    (target) => {
      if (!isShow) return;
      logT("click-container", "close click container because click outside");
      setIsShow(false);
      if (onClick) onClick();
    },
    clickContainer,
    [isShow],
    () => isShow,
  );

  return (
    <div className="clickOutsideContainer" ref={clickContainer}>
      {children}
    </div>
  );
};

export default ClickOutsideContainer;
