import { useEffect, useRef } from "react";

function useDidUpdateEffect(fn, inputs, once = false) {
  const didMountRef = useRef(false);
  const limit = process.env.NODE_ENV == "production" ? 1 : 2;
  useEffect(() => {
    if (!didMountRef.current) didMountRef.current = 1;
    else didMountRef.current++;

    if (didMountRef.current < limit + 1) {
      logT("useDidUpdateEffect", "ignore", didMountRef.current);
      return;
    }

    if (once && didMountRef.current > limit + 1) {
      logT("useDidUpdateEffect", "ignore", didMountRef.current);
      return;
    }

    return fn();
  }, inputs);
}

export default useDidUpdateEffect;
