import React, { useState, useEffect } from "react";
import TalkvioConfig from "../talkvio.json";

const GoogleLogin = ({ children, onLogin }) => {
  const googleSignIn = () => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: TalkvioConfig.google_client_id,
        callback: onLogin,
      });
      window.google.accounts.id.prompt();
    } else if (
      typeof window.TalkvioAndroid != "undefined" &&
      !window.googleToken
    ) {
      logT("android", "login with googleToken");
      window.TalkvioAndroid.googleLogin();
    } else {
      console.error("google is unavailable");
    }
  };

  useEffect(() => {
    if (window.googleToken) {
      logT("android", "login with provided google token", window.googleToken);
      onLogin({ credential: window.googleToken });
    }
  }, []);

  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        googleSignIn();
      }}
    >
      {children}
    </div>
  );
};
export default GoogleLogin;
