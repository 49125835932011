import React, { useState, useEffect } from "react";
import "./LinearProgress.css";

const LinearProgress = ({ color, backgroundColor, value, className }) => {
  return (
    <progress
      value={value}
      max={100}
      style={{
        color: color,
        backgroundColor: backgroundColor,
      }}
      className={
        "pure-material-progress-linear" + (className ? " " + className : "")
      }
    />
  );
};
export default LinearProgress;
