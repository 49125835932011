import { setCSSStyle } from "./LoadCss";

const onRegisterHook = ({ user }) => {
  logT("hook", "onRegisterHook");
  if (window.ym) {
    window.ym(95000227, "reachGoal", "tolkivo");
  }
  if (window.gtag) {
    window.gtag("event", "conversion", {
      send_to: "AW-952868021/9iI7CK-47NwZELW5rsYD",
      value: 1.0,
      currency: "USD",
    });
    window.gtag("event", "registration_done", {
      userid: user?.userid,
      username: user?.username,
    });
  }
};

const onLoadSettings = (user) => {
  const settings = user?.settings;
  if (!settings) {
    return;
  }

  if (typeof settings.videoVolume != "undefined") {
    logT("volume", "set default video volume", settings.videoVolume);
    window.videoPlayerVolume = settings.videoVolume;
  }
  if (typeof settings.videoMute != "undefined") {
    logT("volume", "set default video mute", settings.videoMute);
    window.videoPlayerVolumeMuted = settings.videoMute;
  }

  if (typeof settings.hints == "undefined" || settings.hints) {
    logT("settings", "set hints =", true);
    setCSSStyle("hintsStyle", "");
  } else {
    logT("settings", "set hints =", false);
    setCSSStyle("hintsStyle", ".tooltip .tooltiptext { display: none; }");
  }
};

export { onRegisterHook, onLoadSettings };
