import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const RestorePassword = () => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const { confirmid } = useParams();

  const fetchData = async () => {
    const data = await window.TALKVIOAPI("confirmPassword", { confirmid });
    window.displayError(data);
    setPasswordChanged(data.success);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    passwordChanged && (
      <div className="resore">{__("Your password has been changed")}</div>
    )
  );
};
export default RestorePassword;
