import React, { useState, useEffect } from "react";
import RecentPosts from "./RecentPosts";

const Main = () => {
  useEffect(() => {
    document
      ?.querySelector('meta[name="description"]')
      ?.setAttribute(
        "content",
        __(
          "Talkvio is a social network for communication and connection with people by interest, hobbies, and passions with possibility to create posts and promote own self-made content.",
        ),
      );
    document
      ?.querySelector('meta[name="keywords"]')
      ?.setAttribute(
        "content",
        __(
          "talk, posting, social network, hobbies, interest, promote content, posting, repost",
        ),
      );
    document.title = `${__("Main page")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="main"
          noLimitation={true}
          popular={true}
          cleverPopular={true}
          leftRatingDisplay={true}
        />
      </div>
    </div>
  );
};
export default Main;
