import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Thread from "./Thread";
import AutoScrollable from "../global/AutoScrollable";
import { LoginContext } from "./Login";
import ReplyForm from "./ReplyForm";
import { getCookie } from "../global/Global";
import TabsSwitcher from "./TabsSwitcher";

const Threads = () => {
  const [threads, setThreads] = useState([]);
  const { forumid } = useParams();
  const { user } = useContext(LoginContext);
  const navigate = useNavigate();

  const fetchData = async (full = false) => {
    let index = (threads && threads.length) || 0;
    let limit = 25;
    if (full) {
      index = 0;
      limit = Math.max(25, threads.length);
    }
    const data = await window.TALKVIOAPI("threads", {
      forumid: parseInt(forumid),
      index,
      limit,
    });
    if (!full) setThreads((threads || []).concat(data.threads));
    else setThreads(data.threads);

    if (data.threads?.length > 0) {
      document.title = `${data.threads[0].forumtitle} - Talkvio`;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AutoScrollable onBottomScroll={() => fetchData()}>
      <div className="mainTitle sectionSwitcher">
        <TabsSwitcher
          slow={true}
          value={"forum"}
          onChange={(value) => {
            if (value == "section") {
              navigate(`/section/${forumid}`);
            }
          }}
          values={{
            forum: __("List"),
            section: __("Posts"),
          }}
        />
      </div>
      <ul className="threads threadlist">
        {user && user.userid > 0 && (
          <ReplyForm
            className="typicalBlock"
            user={user}
            forumid={parseInt(forumid)}
            collapsed={true}
            onReply={() => {
              fetchData(true);
            }}
          />
        )}
        {user?.isAdmin ? (
          <div
            className="clickButton"
            onClick={async () => {
              window.confirmMessage("Удалить?", async () => {
                const token = getCookie("token");
                const userid = parseInt(getCookie("userid"));
                const data = await window.TALKVIOAPI("deleteForumPosts", {
                  userid,
                  token,
                  forumid: parseInt(forumid),
                });
              });
            }}
          >
            Удалить посты
          </div>
        ) : null}
        {threads.map((thread) => (
          <Thread thread={thread} key={thread.threadid} />
        ))}
      </ul>
    </AutoScrollable>
  );
};
export default Threads;
