import React, { useEffect, useState, useRef } from "react";
import "./CheckboxSwitcher.css";

function guidGenerator() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export default ({ onSwitch, value, text, className }) => {
  const [checked, setChecked] = useState(value || false);
  const guid = useRef(guidGenerator());

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <div
      className={
        "switcher" +
        (checked ? " checked" : "") +
        (className ? ` ${className}` : "")
      }
    >
      <input
        type="checkbox"
        id={guid?.current}
        checked={checked}
        onChange={() => {
          if (onSwitch) onSwitch(!checked);
          setChecked(!checked);
        }}
      />
      <label for={guid?.current}>{text}</label>
    </div>
  );
};
