import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import RecentPosts from "./RecentPosts";

const SearchPage = () => {
  const { state } = useLocation();
  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="search"
          noLimitation={true}
          search={state?.search}
          leftRatingDisplay={true}
        />
      </div>
    </div>
  );
};
export default SearchPage;
