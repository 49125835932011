import React, { useEffect, useContext } from "react";
import { LoginContext } from "./Login";
import Menu from "./Menu";
import { getCookie } from "../global/Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

const Blocks = () => {
  const { user, reloadUserInfo } = useContext(LoginContext);
  useEffect(() => {
    if (!user) return;

    document.title = `${__("Blocks")} - Talkvio`;
  }, [user]);

  if (!user) {
    return;
  }

  return (
    <div className="blocksPage typicalBlock">
      <div className="blockedTags">
        <div className="title">
          <FontAwesomeIcon icon={faTags} />
          &nbsp; {__("Blocked tags")}
        </div>
        {user.blocktags ? (
          <ul className="taglist">
            {user.blocktags.split(",").map((blockedTag, index) => (
              <li className="tag" key={index}>
                <Menu
                  items={[
                    {
                      name: __("Unblock"),
                      click: async (close) => {
                        await window.TALKVIOAPI("blockTag", {
                          userid: user.userid,
                          token: getCookie("token"),
                          tag: blockedTag,
                        });
                        close();
                      },
                    },
                  ]}
                >
                  #{blockedTag}
                </Menu>
              </li>
            ))}
          </ul>
        ) : (
          <p>{__("No blocked tags")}</p>
        )}
      </div>
    </div>
  );
};

export default Blocks;
