import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../global/UseOutsideClick";
import { isMobile } from "../global/Global";

const MouseFocusContainer = ({ children, menu, condition }) => {
  const mouseContainer = useRef();
  const mouseTimeout = useRef(null);
  const [focusDisplay, setFocusDisplay] = useState(false);

  useEffect(() => {
    if (!mouseContainer.current) return;

    if (isMobile()) {
      return;
    }

    if (typeof condition != "undefined" && !condition) return;

    const onEnter = () => {
      logT("mousefocus", "enter the focus element");
      mouseContainer.current.classList.add("active");
      setFocusDisplay(true);
      if (mouseTimeout.current) {
        clearTimeout(mouseTimeout.current);
        mouseTimeout.current = null;
      }
      if (window.mouseTimeoutTalkvio) {
        window.mouseTimeoutTalkvio();
        window.mouseTimeoutTalkvio = null;
      }
    };
    const onLeave = () => {
      logT("mousefocus", "leave the focus element");
      window.mouseTimeoutTalkvio = () => {
        if (!mouseTimeout.current || !mouseContainer.current) return;
        mouseContainer.current.classList.remove("active");
        setTimeout(() => setFocusDisplay(false), 200);
        mouseTimeout.current = null;
      };
      mouseTimeout.current = setTimeout(window.mouseTimeoutTalkvio, 1000);
    };
    mouseContainer.current.addEventListener("mouseenter", onEnter);
    mouseContainer.current.addEventListener("mouseleave", onLeave);
    return () => {
      mouseContainer.current?.removeEventListener("mouseenter", onEnter);
      mouseContainer.current?.removeEventListener("mouseleave", onLeave);
    };
  }, [condition]);

  return (
    <div className="mouseFocusContainer" ref={mouseContainer}>
      {children}
      {focusDisplay ? <div className="focusContainer">{menu}</div> : null}
    </div>
  );
};

export default MouseFocusContainer;
