import React, { useEffect, useState } from "react";
import "./Checkbox.css";

export default ({ onSwitch, value, text, className }) => {
  const [checked, setChecked] = React.useState(value || false);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <label
      tabIndex="0"
      className={"checkbox" + (className ? " " + className : "")}
    >
      <span className="checkbox-text">{text}</span>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (onSwitch) onSwitch(!checked);
          setChecked(!checked);
        }}
      />
      <span className="checkmark"></span>
    </label>
  );
};
