import { useEffect, useLayoutEffect, useRef, useState } from "react";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function getDistance(touch1, touch2) {
  const dx = touch2.clientX - touch1.clientX;
  const dy = touch2.clientY - touch1.clientY;
  return Math.sqrt(dx * dx + dy * dy);
}

export default ({
  src,
  description,
  descriptionAlt,
  height,
  width,
  onNextPicture,
  onPrevPicture,
  zoomed,
  onCloseZoom,
}) => {
  const imageContainer = useRef();
  const imageRef = useRef();

  const isCurrentlyZoomed = useRef(false);

  // Convert variables to refs
  const panning = useRef(false);
  const scale = useRef(1);
  const pointX = useRef(0);
  const pointY = useRef(0);
  const startX = useRef(0);
  const startY = useRef(0);
  const initialTouchDistance = useRef(0);
  const initialTouchScale = useRef(0);
  const mouseMoved = useRef(false);
  const touchMoveAction = useRef(false);
  const touchScaledAction = useRef(false);

  const eventHandlers = useRef({
    mouseDown: (e) => {
      e.preventDefault();
      startX.current = e.clientX - pointX.current;
      startY.current = e.clientY - pointY.current;
      panning.current = true;
      mouseMoved.current = false;
    },
    mouseUp: () => {
      panning.current = false;
    },
    wheel: (e) => {
      e.preventDefault();
      let delta = e.wheelDelta ? e.wheelDelta : -e.deltaY;
      if (delta > 0) scale.current *= 1.15;
      else scale.current /= 1.15;
      setTransform();
    },
    mouseMove: (e) => {
      e.preventDefault();
      if (!panning.current) {
        return;
      }
      pointX.current = e.clientX - startX.current;
      pointY.current = e.clientY - startY.current;
      setTransform();
      mouseMoved.current = true;
    },
    mouseOutScreen: () => {
      logT("zoom", "mouse out of screen");
      panning.current = false;
    },
    touchStart: (e) => {
      logT("zoomTouch", "start", "pointers", e.touches.length);
      e.preventDefault();
      panning.current = true;
      if (e.touches.length === 2) {
        initialTouchScale.current = scale.current;
        initialTouchDistance.current = getDistance(e.touches[0], e.touches[1]);
      } else if (e.touches.length === 1) {
        initialTouchScale.current = scale.current;
        startX.current = e.touches[0].clientX - pointX.current;
        startY.current = e.touches[0].clientY - pointY.current;
      }

      touchScaledAction.current = false;
      touchMoveAction.current = false;
    },
    touchMove: (e) => {
      if (e.touches.length == 2) {
        e.preventDefault();
        const currentTouchDistance = getDistance(e.touches[0], e.touches[1]);
        if (initialTouchDistance.current > 0) {
          let touchScale = currentTouchDistance / initialTouchDistance.current;
          scale.current = initialTouchScale.current * touchScale;
          setTransform();
        }
        touchScaledAction.current = true;
      } else if (e.touches.length == 1) {
        e.preventDefault();
        if (!touchScaledAction.current) {
          pointX.current = e.touches[0].clientX - startX.current;
          pointY.current = e.touches[0].clientY - startY.current;
          setTransform();
        }
      }

      touchMoveAction.current = true;
    },
    touchEnd: (e) => {
      logT("zoomTouch", "end", "pointers", e.touches.length);
      panning.current = false;
      if (e.touches.length == 0 && !touchMoveAction.current) {
        logT(
          "zoomTouch",
          "end",
          "close image (touch no move)",
          mouseMoved.current,
        );
        setZoomed(false);
      }
    },
    keyLeft: (e) => {
      if (onPrevPicture) {
        logT("zoom", "prev picture");
        e.preventDefault();
        e.stopPropagation();
        setZoomed(false, true);
        onPrevPicture();
      }
    },
    keyRight: (e) => {
      if (onNextPicture) {
        logT("zoom", "next picture");
        e.preventDefault();
        e.stopPropagation();
        setZoomed(false, true);
        onNextPicture();
      }
    },
  });

  function setTransform() {
    imageRef.current.style.transform =
      "translate(" +
      pointX.current +
      "px, " +
      pointY.current +
      "px) scale(" +
      scale.current +
      ")";
  }

  const setZoomed = (zoomed = false, noExit = false) => {
    if (!imageContainer?.current) {
      return;
    }

    if (isCurrentlyZoomed.current && !zoomed) {
      logT("zoom", "stop image zoom");

      document.body.style.overflow = "initial";
      pointX.current = 0;
      pointY.current = 0;
      scale.current = 1;
      mouseMoved.current = false;
      touchMoveAction.current = false;
      touchScaledAction.current = false;
      setTransform();
      imageRef.current.style.transform = "initial";
      imageRef.current.removeEventListener(
        "mousedown",
        eventHandlers.current.mouseDown,
      );
      imageRef.current.removeEventListener(
        "mouseup",
        eventHandlers.current.mouseUp,
      );
      imageRef.current.removeEventListener(
        "wheel",
        eventHandlers.current.wheel,
      );
      imageRef.current.removeEventListener(
        "mousemove",
        eventHandlers.current.mouseMove,
      );
      imageRef.current.removeEventListener(
        "touchstart",
        eventHandlers.current.touchStart,
      );
      imageRef.current.removeEventListener(
        "touchmove",
        eventHandlers.current.touchMove,
      );
      imageRef.current.removeEventListener(
        "touchend",
        eventHandlers.current.touchEnd,
      );
      document.removeEventListener(
        "mouseleave",
        eventHandlers.current.mouseOutScreen,
      );
      window.KEY_OFF("ArrowLeft", eventHandlers.current.keyLeft);
      window.KEY_OFF("ArrowRight", eventHandlers.current.keyRight);

      isCurrentlyZoomed.current = false;
      imageContainer.current.classList.toggle("zoomed");

      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(true);
      }
      window.unblockHeader("imageZoom");

      if (!noExit && onCloseZoom) onCloseZoom();
    } else if (!isCurrentlyZoomed.current && zoomed) {
      logT("zoom", "start image zoom");

      document.body.style.overflow = "hidden";
      imageRef.current.addEventListener(
        "mousedown",
        eventHandlers.current.mouseDown,
      );
      imageRef.current.addEventListener(
        "mouseup",
        eventHandlers.current.mouseUp,
      );
      imageRef.current.addEventListener("wheel", eventHandlers.current.wheel);
      imageRef.current.addEventListener(
        "mousemove",
        eventHandlers.current.mouseMove,
      );
      imageRef.current.addEventListener(
        "touchstart",
        eventHandlers.current.touchStart,
      );
      imageRef.current.addEventListener(
        "touchmove",
        eventHandlers.current.touchMove,
      );
      imageRef.current.addEventListener(
        "touchend",
        eventHandlers.current.touchEnd,
      );
      document.addEventListener(
        "mouseleave",
        eventHandlers.current.mouseOutScreen,
      );
      window.KEY_ON("ArrowLeft", eventHandlers.current.keyLeft, "zoom");
      window.KEY_ON("ArrowRight", eventHandlers.current.keyRight, "zoom");

      initialTouchScale.current = 0;

      const naturalWidth = imageRef.current.naturalWidth;
      const naturalHeight = imageRef.current.naturalHeight;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const screenScale = windowWidth > 850 ? 0.8 : 1;

      const scaleWidth = (windowWidth / naturalWidth) * screenScale;
      const scaleHeight = (windowHeight / naturalHeight) * screenScale;
      scale.current = Math.min(scaleWidth, scaleHeight);

      pointX.current =
        -(naturalWidth - naturalWidth * scale.current) / 2 +
        (windowWidth - naturalWidth * scale.current) / 2;
      pointY.current =
        -(naturalHeight - naturalHeight * scale.current) / 2 +
        (windowHeight - naturalHeight * scale.current) / 2;

      setTransform();
      isCurrentlyZoomed.current = true;
      imageContainer.current.classList.toggle("zoomed");

      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(false);
      }
      window.blockHeader("imageZoom");
    }
  };

  useDidUpdateEffect(() => {
    setZoomed(zoomed);
  }, [zoomed]);

  useLayoutEffect(() => {
    return () => {
      setZoomed(false);
    };
  }, []);

  return (
    <div
      ref={imageContainer}
      className="imageContainer"
      itemProp="image"
      itemScope
      itemType="http://schema.org/ImageObject"
    >
      <img
        height={height}
        width={width}
        ref={imageRef}
        src={src}
        className="zoomImage"
        alt={descriptionAlt}
        itemProp="contentUrl"
        onClick={() => {
          if (mouseMoved.current || touchMoveAction.current) return;
          setZoomed(!isCurrentlyZoomed.current);
        }}
      />
      {description ? (
        <figcaption itemProp="caption" className="imageDescription">
          {description}
        </figcaption>
      ) : null}
      <meta itemProp="creditText" content={descriptionAlt} />
      <div
        className="overlay"
        onClick={() => {
          setZoomed(false);
        }}
      ></div>
      {onNextPicture ? (
        <div
          className="nextPicture"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setZoomed(false, true);
            onNextPicture();
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      ) : null}
      {onPrevPicture ? (
        <div
          className="prevPicture"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setZoomed(false, true);
            onPrevPicture();
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </div>
      ) : null}
    </div>
  );
};
