import { useEffect, useRef } from "react";

function useOutsideClick(
  fn,
  componentRef,
  components = [],
  condition,
  options,
) {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef?.current &&
        !componentRef.current.contains(event.target)
      ) {
        // logT('outsideClick', 'click')
        fn(event.target);
      }
    };

    let registred = false;
    if (condition && condition()) {
      // logT('outsideClick', 'registred')
      document.addEventListener(
        options?.onDownClick ? "mousedown" : "click",
        handleClickOutside,
      );
      registred = true;
    } else if (!condition) {
      // logT('outsideClick', 'registred')
      document.addEventListener(
        options?.onDownClick ? "mousedown" : "click",
        handleClickOutside,
      );
      registred = true;
    }
    return () => {
      if (registred) {
        // logT('outsideClick', 'unregistred')
        document.removeEventListener(
          options?.onDownClick ? "mousedown" : "click",
          handleClickOutside,
        );
      }
    };
  }, components);
}

export default useOutsideClick;
