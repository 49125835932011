import React, { useState, useEffect, useContext } from "react";
import { getCookie } from "../global/Global";
import { LoginContext } from "./Login";
import md5 from "md5";
import ReplyForm from "./ReplyForm";
import { useNavigate } from "react-router-dom";

const Editor = ({ onSend, json, onJsonParsed }) => {
  const { user } = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window.TalkvioAndroid != "undefined") {
      window.TalkvioAndroid.setPageScrollReload(false);
    }
    logT("editor", "enable key blocking");
    window.blockKeyTag("posts", "editor");
    window.blockHeader("editor");
    return () => {
      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(true);
      }
      logT("editor", "disable key blocking");
      window.unblockKeyTag("posts", "editor");
      window.unblockHeader("editor");
    };
  }, []);

  return (
    <div className="editor header-related">
      <ReplyForm
        user={user}
        editorSource="stickyEditor"
        json={json}
        onJsonParsed={onJsonParsed}
        onReply={(data) => {
          if (!data) return;

          navigate("/threads/" + data?.post?.threadid);
          if (onSend) onSend(data);
        }}
      />
    </div>
  );
};
export default Editor;
