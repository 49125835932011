//icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState, useEffect, useContext, useRef } from "react";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";
import { eraseCookie, getCookie, isMobile } from "../global/Global";
import { LoginContext } from "./Login";
import Editor from "./Editor";
import DefaultAvatar_150 from "../default-avatar_150.png";
import Search from "./Search";
import { TranslationContext } from "./Translations";
import { availableLanguage, availableLocales } from "../translate";
import NotificationList from "./NotificationList";
import useOutsideClick from "../global/UseOutsideClick";
import Tooltip from "./Tooltip";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import {
  faBars,
  faMinus,
  faPlus,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord, faTelegram } from "@fortawesome/free-brands-svg-icons";
import useScroll from "../global/UseScroll";

window.blockedHeadersIds = new Set();

const Header = () => {
  const { user } = useContext(LoginContext);
  const { setTranslation } = useContext(TranslationContext);
  const [isOpenLoginForm, setIsOpenLoginForm] = useState(false);
  const [isLeftMenu, setLeftMenu] = useState(false);
  const [isRightMenu, setRightMenu] = useState(false);
  const [isEditor, setEditor] = useState(false);
  const [isNotificationWindow, setNotificationWindow] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [showLeftSocials, setShowLeftSocials] = useState(false);
  const [donateMenu, setDonateMenu] = useState(false);
  const [clientMenu, setClientMenu] = useState(false);
  const [helpMenu, setHelpMenu] = useState(false);
  const [editorJson, setEditorJson] = useState(null);
  const headerEl = useRef();
  const rightPanel = useRef();
  const leftPanel = useRef();
  const loginForm = useRef();
  const collapsedHeaderRef = useRef(false);
  const collapsedMouseHeaderRef = useRef(false);
  const collapsedScrollHeaderRef = useRef(false);
  const stickedTopHeaderRef = useRef(false);
  const lastScrollValue = useRef(window.scrollY);
  const headerOffeseted = useRef(0);
  const headerOffesetedRender = useRef(0);
  const headerTopScrollWaiter = useRef(0);
  const headerAnimation = useRef(false);
  const TOP_OFFSET_COLLAPSE = 80;

  useDidUpdateEffect(() => {
    if (!helpMenu) return;

    document.getElementById("help-menu-last").scrollIntoView({ block: "end" });
  }, [helpMenu]);

  useDidUpdateEffect(() => {
    if (!clientMenu) return;

    document
      .getElementById("client-menu-last")
      .scrollIntoView({ block: "end" });
  }, [clientMenu]);

  useDidUpdateEffect(() => {
    if (!donateMenu) return;

    document
      .getElementById("donate-menu-last")
      .scrollIntoView({ block: "end" });
  }, [donateMenu]);

  useDidUpdateEffect(() => {
    if (!showLeftSocials) return;

    document
      .getElementById("social-menu-last")
      .scrollIntoView({ block: "end" });
  }, [showLeftSocials]);

  useDidUpdateEffect(() => {
    if (!showLanguages) return;

    document
      .getElementById("languages-menu-last")
      .scrollIntoView({ block: "end" });
  }, [showLanguages]);

  useDidUpdateEffect(() => {
    if (isLeftMenu) {
      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(false);
        logT("menu", "block android scroll because menu");
      }
      window.blockHeader("leftMenu");
    } else {
      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(true);
        logT("menu", "unblock android scroll because menu");
      }
      window.unblockHeader("leftMenu");
    }
  }, [isLeftMenu]);

  useDidUpdateEffect(() => {
    if (isRightMenu) {
      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(false);
        logT("menu", "block android scroll because menu");
      }
      window.blockHeader("rightMenu");
    } else {
      if (typeof window.TalkvioAndroid != "undefined") {
        window.TalkvioAndroid.setPageScrollReload(true);
        logT("menu", "unblock android scroll because menu");
      }
      window.unblockHeader("rightMenu");
    }
  }, [isRightMenu]);

  useDidUpdateEffect(() => {
    if (isOpenLoginForm) {
      window.blockHeader("loginForm");
    } else {
      window.unblockHeader("loginForm");
    }
  }, [isOpenLoginForm]);

  useOutsideClick(
    (target) => {
      if (!isRightMenu) return;
      if (headerEl?.current.contains(target)) return;

      logT("header", "close left menu, outside click");
      setRightMenu(false);
    },
    rightPanel,
    [isRightMenu],
    () => isRightMenu,
  );

  useOutsideClick(
    (target) => {
      if (!isLeftMenu) return;
      if (headerEl?.current.contains(target)) return;

      logT("header", "close right menu, outside click");
      setLeftMenu(false);
    },
    leftPanel,
    [isLeftMenu],
    () => isLeftMenu,
  );

  useOutsideClick(
    (target) => {
      if (!isOpenLoginForm) return;
      if (headerEl?.current.contains(target)) return;

      logT("header", "close login form, outside click");
      // keep onfocus registation for keys
      window.unblockKeyTag("posts", "header");
      setTimeout(() => setIsOpenLoginForm(false), 0);
    },
    loginForm,
    [isOpenLoginForm],
    () => isOpenLoginForm,
    { onDownClick: true },
  );

  const headerRenderFinish = () => {
    document.documentElement.style.setProperty(
      "--header-top-size",
      `${64 - headerOffeseted.current}px`,
    );
  };

  let headerRenderFrame = 0;
  const headerRender = (timestamp) => {
    if (headerOffeseted.current == headerOffesetedRender.current) {
      headerAnimation.current = false;
      headerRenderFinish();
      return;
    }

    headerRenderFrame++;

    let increase = false;
    if (headerOffeseted.current > headerOffesetedRender.current) {
      increase = true;
      headerOffesetedRender.current += Math.max(
        Math.abs(headerOffeseted.current - headerOffesetedRender.current) / 3,
        1,
      );
    } else {
      headerOffesetedRender.current -= Math.max(
        Math.abs(headerOffeseted.current - headerOffesetedRender.current) / 3,
        1,
      );
    }
    if (headerOffesetedRender.current < 0) headerOffesetedRender.current = 0;
    else if (headerOffesetedRender.current > 64) {
      headerOffesetedRender.current = 64;
    }
    headerEl.current.style.top = `-${headerOffesetedRender.current}px`;
    const headerRelatedElements =
      document.getElementsByClassName("header-related");
    for (const headerRelated of headerRelatedElements) {
      headerRelated.style.top = `${64 - headerOffesetedRender.current}px`;
    }
    if (window.headerAffectedElement?.length > 0) {
      window.talkvioHeaderOffset = headerOffesetedRender.current;
      for (const affected of window.headerAffectedElement) {
        affected();
      }
    }

    // logT(
    //   "debug",
    //   "render",
    //   timestamp,
    //   "frame",
    //   headerRenderFrame,
    //   "realOffset",
    //   headerOffeseted.current,
    //   "renderOffset",
    //   headerOffesetedRender.current,
    // );

    if (
      (!increase && headerOffeseted.current >= headerOffesetedRender.current) ||
      (increase && headerOffeseted.current <= headerOffesetedRender.current)
    ) {
      headerAnimation.current = false;
      headerRenderFinish();
      return;
    }

    requestAnimationFrame(headerRender);
  };

  const onHeaderScroll = (x, y) => {
    if (user?.settings?.fixedTopHeader) {
      return;
    }

    const previousScroll = lastScrollValue.current;
    lastScrollValue.current = y;
    const diff = y - previousScroll;
    // logT('debug', 'y =', y, 'diff =', diff, 'waiter =', headerTopScrollWaiter.current);
    if (y > TOP_OFFSET_COLLAPSE && diff > 0) {
      if (headerOffeseted.current < 64) {
        if (stickedTopHeaderRef.current) {
          return;
        }

        headerOffeseted.current = Math.max(
          Math.min(headerOffeseted.current + diff, 64),
          0,
        );
        window.talkvioHeaderOffset = headerOffeseted.current;
        if (!headerAnimation.current) {
          headerAnimation.current = true;
          headerRenderFrame = 0;
          requestAnimationFrame(headerRender);
        }
        if (headerOffeseted.current >= 64) {
          collapsedHeaderRef.current = true;
          collapsedScrollHeaderRef.current = true;
          logT("header", "set collapsed", collapsedHeaderRef.current);
          // headerEl.current.classList.add("collapsedHeader");
          headerTopScrollWaiter.current = 0;
        }
      } else {
        if (!collapsedScrollHeaderRef.current) {
          collapsedScrollHeaderRef.current = true;
          logT("header", "set collapsed scroll", collapsedHeaderRef.current);
        }
      }
    } else if (y <= TOP_OFFSET_COLLAPSE || diff < 0) {
      if (headerOffeseted.current > 0) {
        // fix bug with scroll intermiddiate trigger on page change
        if (y == 64) {
          return;
        }

        if (headerTopScrollWaiter.current > -45 && y > TOP_OFFSET_COLLAPSE) {
          headerTopScrollWaiter.current += diff;
          return;
        }

        headerOffeseted.current = Math.min(
          Math.max(headerOffeseted.current + diff, 0),
          64,
        );
        window.talkvioHeaderOffset = headerOffeseted.current;
        if (!headerAnimation.current) {
          headerAnimation.current = true;
          headerRenderFrame = 0;
          requestAnimationFrame(headerRender);
        }
        if (headerOffeseted.current <= 0) {
          collapsedHeaderRef.current = false;
          collapsedScrollHeaderRef.current = false;
          logT("header", "set collapsed", collapsedHeaderRef.current);
          // headerEl.current.classList.remove("collapsedHeader");
        }
      } else {
        if (collapsedScrollHeaderRef.current) {
          collapsedScrollHeaderRef.current = false;
          logT("header", "set collapsed scroll", collapsedHeaderRef.current);
        }
      }
    }
  };
  useScroll(onHeaderScroll, null, null, true);

  const collapseHeader = () => {
    if (stickedTopHeaderRef.current) {
      return;
    }
    if (!collapsedScrollHeaderRef.current) {
      return;
    }
    collapsedHeaderRef.current = true;
    headerOffeseted.current = 64;
    window.talkvioHeaderOffset = 64;
    if (!headerAnimation.current) {
      headerAnimation.current = true;
      headerRenderFrame = 0;
      requestAnimationFrame(headerRender);
    }
  };

  const uncollapseHeader = () => {
    collapsedHeaderRef.current = false;
    headerOffeseted.current = 0;
    window.talkvioHeaderOffset = 0;
    if (!headerAnimation.current) {
      headerAnimation.current = true;
      headerRenderFrame = 0;
      requestAnimationFrame(headerRender);
    }
  };

  let headerTimer = null;
  const mouseMoveHandler = (e) => {
    if (!collapsedHeaderRef.current && !collapsedMouseHeaderRef.current) {
      return;
    }
    if (stickedTopHeaderRef.current) {
      return;
    }

    //const x = e.clientX;
    const y = e.clientY;

    // logT("scroll", y, collapsedMouseHeaderRef.current);

    if (y < 75) {
      if (!collapsedMouseHeaderRef.current || collapsedHeaderRef.current) {
        if (window.headerUnblockedAreaStart || window.headerUnblockedAreaEnd) {
          const x = e.clientX;
          if (
            x >= window.headerUnblockedAreaStart &&
            x <= window.headerUnblockedAreaEnd
          ) {
            return;
          }
        }

        logT("header", "uncollapse header with mouse");
        collapsedMouseHeaderRef.current = true;
        uncollapseHeader();
      }
      if (headerTimer) {
        logT("header", "reset header timer");
        clearTimeout(headerTimer);
        headerTimer = null;
      }
    } else {
      if (
        collapsedMouseHeaderRef.current &&
        window.scrollY > TOP_OFFSET_COLLAPSE
      ) {
        if (!headerTimer) {
          headerTimer = setTimeout(() => {
            logT("header", "collapse header with mouse");
            collapsedMouseHeaderRef.current = false;
            collapseHeader();
          }, 3000);
        }
      }
    }
  };

  window.blockHeader = (id) => {
    if (user?.settings?.fixedTopHeader) {
      return;
    }

    if (id) {
      if (window.blockedHeadersIds.has(id)) {
        return;
      }
      window.blockedHeadersIds.add(id);
      logT("header", "set blocked header at top", id);
    }

    logT("header", "blocked header at top", id);
    stickedTopHeaderRef.current = true;
    if (!collapsedHeaderRef.current) {
      uncollapseHeader();
    }
  };

  window.unblockHeader = (id) => {
    if (user?.settings?.fixedTopHeader) {
      return;
    }

    if (id) {
      if (!window.blockedHeadersIds.has(id)) {
        return;
      }
      logT("header", "unset blocked header at top", id);
      window.blockedHeadersIds.delete(id);
      if (window.blockedHeadersIds.size > 0) {
        return;
      }
    }

    logT("header", "unblocked header at top", id);
    stickedTopHeaderRef.current = false;
  };

  useEffect(() => {
    if (isMobile()) {
      return;
    }

    if (user?.settings?.fixedTopHeader) {
      return;
    }

    if (user?.settings?.topHeaderNoMouse) {
      return;
    }

    logT("header", "listen mouse for header");
    document.addEventListener("mousemove", mouseMoveHandler);
    return () => {
      logT("header", "unlisten mouse for header");
      document.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [user]);

  useDidUpdateEffect(() => {
    if (user?.userid > 0 && isOpenLoginForm) {
      logT("login", "close login form as we performed enter");
      setIsOpenLoginForm(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.settings?.fixedTopHeader) {
      headerOffeseted.current = 0;
      window.talkvioHeaderOffset = 0;
      headerEl.current.style.top = `0px`;
      document.documentElement.style.setProperty(
        "--header-top-size",
        `${64 - headerOffeseted.current}px`,
      );
    }
    window.talkvioHeaderFixed = user?.settings?.fixedTopHeader;
  }, [user]);

  window.needRegistationAction = (ignoreWarn = false) => {
    if (user && user.userid > 0) return;

    setIsOpenLoginForm(true);
    if (!ignoreWarn)
      window.showWarn(
        __("Need registation"),
        __(
          "This action need registation or login, it will be opened a lot of possibilities on Talkvio after registation.",
        ),
      );
  };

  window.openEditor = (json = null) => {
    const myUserid = parseInt(getCookie("userid"));
    if (myUserid > 0) {
      logT("editor", "open editor globally");
      setEditorJson(json);
      setEditor(true);
    } else {
      logT("editor", "open editor globally, but user is not logged in");
    }
  };

  return (
    <>
      <header
        onFocus={() => {
          window.blockKeyTag("posts", "header");
        }}
        onBlur={() => {
          window.unblockKeyTag("posts", "header");
        }}
        ref={headerEl}
        className="App-header"
      >
        <div
          className="menu"
          onClick={() => {
            setLeftMenu(!isLeftMenu);
            setRightMenu(false);
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
        <Link className="logoContainer" to="/">
          <div className="logo"></div>
        </Link>
        <nav
          className={isLeftMenu ? "leftMenu header-related" : "mainMenu"}
          ref={leftPanel}
        >
          <Link to="/" onClick={() => setLeftMenu(false)} className="onlyLeft">
            {__("Main")}
          </Link>
          <Link to="/sections" onClick={() => setLeftMenu(false)}>
            {__("Sections")}
          </Link>
          {user?.userid ? (
            <Link
              to={`/user/${user.userid}`}
              onClick={() => setLeftMenu(false)}
              className="onlyLeft"
            >
              {__("My account")}
            </Link>
          ) : null}
          <Link to="/activity" onClick={() => setLeftMenu(false)}>
            {__("Actual")}
          </Link>
          <Link to="/top" onClick={() => setLeftMenu(false)}>
            {__("Top")}
          </Link>
          <Link to="/users" onClick={() => setLeftMenu(false)}>
            {__("Users")}
          </Link>
          {user?.userid ? (
            <>
              <Link
                to="/subscriptions"
                className="optional"
                onClick={() => setLeftMenu(false)}
              >
                {__("Subscriptions")}
              </Link>
              <Link
                to="/bookmarks"
                className="onlyLeft"
                onClick={() => {
                  setRightMenu(false);
                  setLeftMenu(false);
                }}
              >
                {__("Bookmarks")}
              </Link>
            </>
          ) : null}
          <Link
            to="/original"
            className="optional"
            onClick={() => setLeftMenu(false)}
          >
            {__("Authors")}
          </Link>
          <Link
            to="/mostcommented"
            className="optional"
            onClick={() => setLeftMenu(false)}
          >
            {__("Most commented")}
          </Link>
          <Link
            to="/random"
            className="optional"
            onClick={() => setLeftMenu(false)}
          >
            {__("Random")}
          </Link>
          <Link
            to="/users"
            onClick={(e) => {
              setShowLanguages(!showLanguages);
              e.preventDefault();
              e.stopPropagation();
            }}
            className="onlyLeft"
          >
            {__("Language")}
          </Link>
          {showLanguages
            ? Object.keys(process.env.TRANSLATIONS).map((tr, i) => (
                <Link
                  id={
                    i == Object.keys(process.env.TRANSLATIONS).length - 1
                      ? "languages-menu-last"
                      : null
                  }
                  to="/users"
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTranslation(tr);
                  }}
                  className="onlyLeft"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{availableLanguage(tr)}
                </Link>
              ))
            : null}
          <Link
            to="/users"
            onClick={(e) => {
              setShowLeftSocials(!showLeftSocials);
              e.preventDefault();
              e.stopPropagation();
            }}
            className="onlyLeft"
          >
            {__("Social Networks")}
          </Link>
          {showLeftSocials ? (
            <>
              <Link
                to="https://t.me/talkvio"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Telegram
              </Link>
              <Link
                to="https://discord.com/invite/jnxZdr58hn"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Discord
              </Link>
              <Link
                to="https://www.youtube.com/@Talkvio"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Youtube
              </Link>
              <Link
                to="https://twitter.com/talkvio"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Twitter
              </Link>
              <Link
                id="social-menu-last"
                to="https://vk.com/talkvio"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;VK
              </Link>
            </>
          ) : null}
          <Link
            to="/users"
            onClick={(e) => {
              setDonateMenu(!donateMenu);
              e.preventDefault();
              e.stopPropagation();
            }}
            className="onlyLeft"
          >
            {__("Donate")}
          </Link>
          {donateMenu ? (
            <>
              <Link
                to="https://patreon.com/talkvio"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Patreon
              </Link>
              <Link
                id="donate-menu-last"
                to="https://boosty.to/talkvio"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Boosty
              </Link>
            </>
          ) : null}
          <Link
            to="/users"
            onClick={(e) => {
              setClientMenu(!clientMenu);
              e.preventDefault();
              e.stopPropagation();
            }}
            className="onlyLeft"
          >
            {__("Client")}
          </Link>
          {clientMenu ? (
            <>
              <Link
                to="https://play.google.com/store/apps/details?id=com.talkvio.app&hl=ru_RU"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{__("On")} Play Market
              </Link>
              <a
                id="client-menu-last"
                href="/files/talkvio.apk"
                target="_blank"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{__("Download")} APK
              </a>
            </>
          ) : null}
          <Link
            to="/users"
            onClick={(e) => {
              setHelpMenu(!helpMenu);
              e.preventDefault();
              e.stopPropagation();
            }}
            className="onlyLeft"
          >
            {__("Help")}
          </Link>
          {helpMenu ? (
            <>
              <Link
                to="/threads/29467-rukovodstvo-po-talkvio"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{__("Manual")}
              </Link>
              <Link
                id="help-menu-last"
                to="/threads/29468-soobschit-o-probleme--idee"
                className="onlyLeft"
                onClick={() => setLeftMenu(false)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;{__("Report a bug")}
              </Link>
            </>
          ) : null}
        </nav>
        {isRightMenu ? (
          <div className="rightMenu header-related" ref={rightPanel}>
            {user?.userid > 0 ? (
              <>
                <Link
                  to={"/user/" + user.userid}
                  onClick={() => setRightMenu(false)}
                >
                  {__("My profile")}
                </Link>
                <Link
                  to=""
                  onClick={(e) => {
                    setRightMenu(false);
                    setNotificationWindow(true);
                    e.preventDefault();
                  }}
                >
                  {__("Notifications")}
                </Link>
                <Link to="/subscriptions" onClick={() => setRightMenu(false)}>
                  {__("Subscriptions")}
                </Link>
                <Link to="/answers" onClick={() => setRightMenu(false)}>
                  {__("Answers")}
                </Link>
                <Link to="/bookmarks" onClick={() => setRightMenu(false)}>
                  {__("Bookmarks")}
                </Link>
                <Link to="/mycomments" onClick={() => setRightMenu(false)}>
                  {__("My comments")}
                </Link>
                <Link to="/delayedposts" onClick={() => setRightMenu(false)}>
                  {__("Delayed posts")}
                </Link>
                <Link to="/settings" onClick={() => setRightMenu(false)}>
                  {__("Settings")}
                </Link>
                <Link to="/blocks" onClick={() => setRightMenu(false)}>
                  {__("Blocks")}
                </Link>
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    eraseCookie("userid");
                    eraseCookie("token");
                    eraseCookie("darkmode");
                    if (typeof window.TalkvioAndroid != "undefined") {
                      window.TalkvioAndroid.setPageScrollReload(true);
                      logT("menu", "unblock android scroll before log out");
                    }
                    if (
                      typeof window.TalkvioAndroid != "undefined" &&
                      window.TalkvioAndroid.logOutPerformed
                    ) {
                      window.TalkvioAndroid.logOutPerformed();
                    }
                    window.location.href = window.location.href;
                  }}
                >
                  {__("Logout")}
                </Link>
              </>
            ) : null}
          </div>
        ) : null}
        <Search
          onSeaching={(searching) => {
            if (searching) headerEl.current.classList.add("searching");
            else headerEl.current.classList.remove("searching");
          }}
        />
        <div className="socialNetworks">
          <div className="description">
            {__("Subscribe")}
            <br /> {__("for notifications")}:
          </div>
          <Link to="https://t.me/talkvio">
            <FontAwesomeIcon icon={faTelegram} />
          </Link>
          <Link to="https://discord.com/invite/jnxZdr58hn">
            <FontAwesomeIcon icon={faDiscord} />
          </Link>
        </div>
        {user && user.userid > 0 ? (
          <div className="addPost" onClick={() => setEditor(!isEditor)}>
            {!isEditor ? (
              <Tooltip hint={__("Click on this to add new post or thread")}>
                <FontAwesomeIcon icon={faPlus} />
              </Tooltip>
            ) : (
              <Tooltip hint={__("Click on this again to hide post creation")}>
                <FontAwesomeIcon icon={faMinus} />
              </Tooltip>
            )}
          </div>
        ) : null}
        <select
          className="languageSwitcher"
          value={getPreferredLanguage()}
          onChange={(event) => {
            setTranslation(event.target.value);
          }}
        >
          {Object.keys(availableLocales()).map((l, i) => (
            <option key={i} value={l}>
              {l.toUpperCase()}
            </option>
          ))}
        </select>
        {user && user.userid > 0 ? (
          <div className="loginInfo">
            <div className="username">
              <Link to={"/user/" + user.userid}>{user.username}</Link>
            </div>
            <div
              className="avatar"
              onClick={(e) => {
                if (user.notifications > 0) {
                  setNotificationWindow(!isNotificationWindow);
                  setLeftMenu(false);
                  setRightMenu(false);
                }
              }}
            >
              <Link
                to=""
                onClick={(e) => {
                  if (!(user.notifications > 0)) {
                    setRightMenu(!isRightMenu);
                    setLeftMenu(false);
                    setNotificationWindow(false);
                  }
                  e.preventDefault();
                }}
              >
                <img
                  src={
                    user.avatarrevision > 0
                      ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                      : DefaultAvatar_150
                  }
                  alt={__("Avatar of user") + " " + user.username}
                />
              </Link>
              {user.notifications > 0 ? (
                <div className="notificationIcon">
                  {Math.min(99, user.notifications)}
                </div>
              ) : null}
            </div>
            {isNotificationWindow ? (
              <NotificationList
                onNotificationClick={() => setNotificationWindow(false)}
                user={user}
                onMenuClick={() => {
                  setRightMenu(true);
                  setNotificationWindow(false);
                }}
                onExitClick={() => {
                  setRightMenu(false);
                  setNotificationWindow(false);
                }}
              />
            ) : null}
          </div>
        ) : (
          <div className="loginInfo">
            <div
              className="loginButton"
              onClick={() => {
                setIsOpenLoginForm(!isOpenLoginForm);
              }}
            >
              <FontAwesomeIcon icon={faRightToBracket} />
              <span>
                {__("Login")} / {__("Registration")}
              </span>
            </div>
          </div>
        )}
        <LoginForm
          setRef={loginForm}
          displayLogin={isOpenLoginForm}
          onClose={() => setIsOpenLoginForm(false)}
        />
      </header>
      {isEditor ? (
        <Editor
          onSend={() => {
            setEditor(false);
          }}
          json={editorJson}
          onJsonParsed={() => {
            logT("editor", "json parsed, reset json");
            setEditorJson(null);
          }}
        />
      ) : null}
    </>
  );
};
export default Header;
