import React, { useEffect, useState, useRef, useContext } from "react";
import useScroll from "../global/UseScroll";
import useResize from "../global/UseResize";
import AskRegistation from "./AskRegistation";
import { LoginContext } from "./Login";
import KeysButtons from "../keybuttons.png";
import CheckboxSwitcher from "./CheckboxSwitcher";
import { getCookie, isMobile, setCookie } from "../global/Global";

import BoostyLogo from "../images/boosty-logo.png";
import PatreonLogo from "../images/patreon-logo.png";
import ApkLogo from "../images/apk-logo.png";
import GooglePlayLogo from "../images/google-play-logo.png";

import Tooltip from "./Tooltip";
import { Link } from "react-router-dom";
import useDidUpdateEffect from "../global/DidUpdateEffect";

const LeftPanel = () => {
  const leftPanel = useRef();
  const DEFAULT_PANEL_OFFSET = 45;
  const topOffset = useRef(DEFAULT_PANEL_OFFSET);
  const onSize = useRef(1565);
  const isDisplay = useRef(false);
  const [stickyDisplay, setStickyDisplay] = useState(false);

  const { user, possiblePreLogin } = useContext(LoginContext);
  const [keyboardHelp, setKeyboardHelp] = useState(
    !getCookie("keyboardhelphide"),
  );

  window.setLeftPanelTopOffet = (value) => {
    if (!leftPanel.current) return;

    topOffset.current = value;
    leftPanel.current.style.top = `${value}px`;
    onScroll(window.pageXOffset, window.pageYOffset);
  };

  window.resetLeftPanelTopOffet = () => {
    topOffset.current = DEFAULT_PANEL_OFFSET;
    window.setLeftPanelTopOffet(DEFAULT_PANEL_OFFSET);
  };

  window.setLeftPanelDisplay = (d) => {
    if (!leftPanel.current) return;

    isDisplay.current = d;
    resizeTrigger(window.innerWidth);
  };

  window.setLeftPanelOnSize = (size = 1565) => {
    onSize.current = size;
    resizeTrigger(window.innerWidth);
  };

  useEffect(() => {
    // default
    window.setLeftPanelTopOffet(topOffset.current);
    window.setKeyboardHelp = (enable) => {
      setCookie("keyboardhelphide", !enable, 30);
      setKeyboardHelp(enable);
    };
  }, []);

  const resizeTrigger = (width) => {
    if (width >= onSize.current && isDisplay.current) {
      if (!stickyDisplay) {
        setStickyDisplay(true);
      }
      if (leftPanel?.current) leftPanel.current.style.display = "block";
    } else {
      if (stickyDisplay && leftPanel?.current) {
        leftPanel.current.style.display = "none";
      }
    }
  };
  useResize(resizeTrigger);

  const onScroll = (x, y) => {
    if (!leftPanel?.current || !topOffset?.current) return;

    // const postListPosition = postsListRef.current.parentElement.getBoundingClientRect();
    const maxOffsetY =
      topOffset.current -
      DEFAULT_PANEL_OFFSET +
      (window.talkvioHeaderOffset || 0);
    if (
      y > maxOffsetY &&
      leftPanel.current.style.transform == `translateY(-${maxOffsetY}px)`
    ) {
      return;
    }
    const newPosition = Math.min(y, maxOffsetY);
    leftPanel.current.style.transform = `translateY(-${newPosition}px)`;
    const TOP_HEADER_OFFSET = 70 - (window.talkvioHeaderOffset || 0);
    leftPanel.current.style.maxHeight = `calc(100% - ${TOP_HEADER_OFFSET + maxOffsetY - newPosition}px)`;
    // logT('stiky', 'offset panel', -newPosition + 'px')
  };
  useScroll(onScroll);

  useEffect(() => {
    if (isMobile()) {
      return;
    }

    const updateScroll = () => {
      if (!stickyDisplay) return;
      onScroll(window.scrollX, window.scrollY);
    };
    if (!window.headerAffectedElement) window.headerAffectedElement = [];
    window.headerAffectedElement.push(updateScroll);
    return () => {
      if (window.headerAffectedElement?.length > 0) {
        const index = window.headerAffectedElement.indexOf(updateScroll);
        if (index >= 0) {
          window.headerAffectedElement.splice(index, 1);
        }
      }
    };
  }, [stickyDisplay]);

  useDidUpdateEffect(() => {
    if (
      !user?.settings?.visualLeftPanelHide &&
      leftPanel.current &&
      stickyDisplay &&
      window.leftPanelPreviuslyDisabled
    ) {
      logT("leftPanel", "initial scroll after panel enable");
      window.leftPanelPreviuslyDisabled = false;
      leftPanel.current.style.top = `${topOffset.current}px`;
      onScroll(window.pageXOffset, window.pageYOffset);
    } else if (user?.settings?.visualLeftPanelHide) {
      window.leftPanelPreviuslyDisabled = true;
    }
  }, [user]);

  if (user?.settings?.visualLeftPanelHide) {
    logT("visual", "hide left panel because of settings");
    return null;
  }

  return (
    <div ref={leftPanel} className="leftBlocksPanel sticky">
      <noindex>
        <div className="w100p flex column">
          {!user && !possiblePreLogin ? <AskRegistation /> : null}
          {keyboardHelp && (
            <div className="keyboardHelp">
              <div className="flex column center-column keyboardStruct">
                <img src={KeysButtons} alt="keyboard button" />
              </div>
              <div className="keyboardDesc typicalBlock">
                <div>
                  <krb>D</krb> - {__("Scroll to next post")}
                </div>
                <div>
                  <krb>A</krb> - {__("Scroll to previous post")}
                </div>
                <div>
                  <krb>W</krb> - {__("Like this post")}
                </div>
                <div>
                  <krb>S</krb> - {__("Dislike current post")}
                </div>
              </div>
              <div className="flex row center-row">
                <CheckboxSwitcher
                  text={
                    __("Dont show") +
                    " " +
                    __("keyboard help") +
                    " " +
                    __("once again")
                  }
                  value={false}
                  onSwitch={(checked) => {
                    if (checked) {
                      setCookie("keyboardhelphide", true, 30);
                      setKeyboardHelp(false);
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div className="typicalBlock donateBlock">
            <div className="description">
              {__("If you like you can donate to support Talkvio project")}
            </div>
            <div className="flex row center-row">
              <Tooltip top={true} hint={__("Support project on Boosty")}>
                <Link to="https://boosty.to/talkvio">
                  <img src={BoostyLogo} alt="support project on boosty" />
                </Link>
              </Tooltip>
              <Tooltip top={true} hint={__("Support project on Patreon")}>
                <Link to="https://patreon.com/talkvio">
                  <img src={PatreonLogo} alt="support project on patreon" />
                </Link>
              </Tooltip>
            </div>
          </div>
          <div className="typicalBlock downloadBlock">
            <div className="description">
              {__("You can download client for your phone")}
            </div>
            <div className="flex row center-row center-column">
              <Tooltip top={true} hint={__("Download at play market")}>
                <Link to="https://play.google.com/store/apps/details?id=com.talkvio.app&hl=ru_RU">
                  <img
                    style={{ width: 150 }}
                    src={GooglePlayLogo}
                    alt="download on play market"
                  />
                </Link>
              </Tooltip>
              <Tooltip top={true} hint={__("APK file directly")}>
                <a href="/files/talkvio.apk">
                  <img src={ApkLogo} alt="download talkvio apk" />
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="linkLeftBlock flex row center-row w100p">
            <Link to="/threads/29467-rukovodstvo-po-talkvio">
              {__("Manual").toLowerCase()}
            </Link>
            <Link to="/threads/29468-soobschit-o-probleme--idee">
              {__("Report a bug").toLowerCase()}
            </Link>
          </div>
        </div>
      </noindex>
    </div>
  );
};
export default LeftPanel;
