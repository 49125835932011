import React, { useState, useEffect, useRef, useContext } from "react";
import { LoginContext } from "./Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

const Overlay = () => {
  const { user } = useContext(LoginContext);
  const PAGE_OFFSET_SCROLLBUTTON = 800;
  const [enabledTopButton, setEnabledTopButton] = useState(
    window.pageYOffset > PAGE_OFFSET_SCROLLBUTTON,
  );
  const enabledTopButtonRef = useRef(
    window.pageYOffset > PAGE_OFFSET_SCROLLBUTTON,
  );

  useEffect(() => {
    const scrollCallback = () => {
      if (
        window.pageYOffset > PAGE_OFFSET_SCROLLBUTTON &&
        !enabledTopButtonRef.current
      ) {
        logT("overlay", "enable scroll button");
        enabledTopButtonRef.current = true;
        setEnabledTopButton(true);
      } else if (
        window.pageYOffset <= PAGE_OFFSET_SCROLLBUTTON &&
        enabledTopButtonRef.current
      ) {
        logT("overlay", "disable scroll button");
        enabledTopButtonRef.current = false;
        setEnabledTopButton(false);
      }
    };

    if (user?.settings?.scrollToTop) {
      logT("overlay", "listening scroll for top button");
      window.addEventListener("scroll", scrollCallback);
    }

    return () => {
      if (user?.settings?.scrollToTop) {
        logT("overlay", "unlistening scroll for top button");
        window.removeEventListener("scroll", scrollCallback);
      }
    };
  }, [user]);

  return (
    <div className="overlay">
      {user?.settings?.scrollToTop ? (
        <div
          className={"topScrollButton" + (enabledTopButton ? " enabled" : "")}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <FontAwesomeIcon icon={faAngleUp} />
        </div>
      ) : null}
    </div>
  );
};
export default Overlay;
