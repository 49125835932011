import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { LoginContext } from "./Login";

const ConfirmPassword = () => {
  const { doLogin } = useContext(LoginContext);

  const fetchData = async () => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const vkcode = searchParams.get("code");
    if (!vkcode) return;
    doLogin({ vkCode: vkcode });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="confirm">{__("You are loggined with your vk account")}</div>
  );
};
export default ConfirmPassword;
