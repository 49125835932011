const setCSSStyle = (id, styleText) => {
  let style = document.getElementById(id);
  let exists = true;
  if (!style) {
    style = document.createElement("style");
    style.id = id;
    exists = false;
  }
  style.type = "text/css";
  if (!exists) {
    if (style.styleSheet) {
      style.styleSheet.cssText = styleText;
    } else {
      style.appendChild(document.createTextNode(styleText));
    }
    document.getElementsByTagName("head")[0].appendChild(style);
  } else {
    if (style.styleSheet) {
      style.styleSheet.cssText = styleText;
    } else {
      style.replaceChild(
        document.createTextNode(styleText),
        style.childNodes[0],
      );
    }
  }
};

export { setCSSStyle };
