const sha3_256 = require("js-sha3").sha3_256;

function included(url) {
  let scripts = document.getElementsByTagName("script");
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].getAttribute("src") === url) {
      return true;
    }
  }
  return false;
}

function loadScript(url, callback, id = null) {
  if (included(url)) {
    if (callback) {
      callback();
    }
    return;
  }

  let script = document.createElement("script");
  script.async = true;
  script.src = url;
  if (id) script.id = id;

  if (callback) {
    script.onload = callback;
  }

  let firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
}

export default loadScript;

function unloadScript(url) {
  let scripts = document.getElementsByTagName("script");
  for (let i = scripts.length; i--; ) {
    if (url instanceof RegExp && url.test(scripts[i].getAttribute("src"))) {
      scripts[i].remove();
    } else if (scripts[i].getAttribute("src") === url) {
      scripts[i].remove();
      return;
    }
  }
}

export { unloadScript };

function loadScriptText(text, callback) {
  if (window.loadedScripts?.[sha3_256(text)]) {
    if (callback) {
      logT("script", "script has already loaded");
      callback();
    }
    return;
  }

  let script = document.createElement("script");
  let code = document.createTextNode(text);
  script.appendChild(code);
  if (!window.loadedScripts) window.loadedScripts = {};
  window.loadedScripts[sha3_256(text)] = true;

  let firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

  if (callback) callback();
}

export { loadScriptText };
