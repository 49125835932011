import React, { Component } from "react";
import useDidUpdateEffect from "./DidUpdateEffect";
import { getStorageValue, setStorageValue, clearStorageValue } from "./Storage";

let routerHistoryId = 0;
class RouterHistory extends Component {
  componentDidMount() {
    routerHistoryId++;
    this.location = (e) => {
      logT(
        "RouterHistory",
        "location trigger",
        "backward =",
        e.detail?.backward,
      );
      if (e.detail?.backward) {
        if (this.props.onBackward) this.props.onBackward();
      } else {
        if (this.props.onLocationChange) this.props.onLocationChange();
      }
      window.removeEventListener("talkvioLocationChange", this.location);
    };
    window.addEventListener("talkvioLocationChange", this.location);
  }

  componentWillUnmount() {
    // On Scrict mode always two renders, first one must be ignored
    if (
      this.props.onLocationChange &&
      (process.env.NODE_ENV == "production" || routerHistoryId % 2 == 0)
    ) {
      logT("RouterHistory", "unmount trigger");
      this.props.onLocationChange();
    }
    window.removeEventListener("talkvioLocationChange", this.location);
  }

  render() {
    return this.props.children;
  }
}

const setScroll = async (
  store,
  source = "",
  value = null,
  onSetScroll = () => {},
) => {
  const scrollValue = value
    ? value
    : await getStorageValue("history:scroll" + (store ? `:${store}` : ""));
  logT(
    "HistoryScroll",
    "restore scroll =",
    scrollValue,
    "store =",
    store,
    "source =",
    source,
  );
  if (scrollValue) {
    window.scrollTo(0, parseInt(scrollValue));
  }
  clearStorageValue("history:scroll" + (store ? `:${store}` : ""));
  if (onSetScroll) onSetScroll(scrollValue);
};

export { setScroll };

export default ({
  children,
  saveData,
  preSaveData,
  onLoadData,
  onSetScroll,
  store = null,
}) => {
  useDidUpdateEffect(
    () => {
      setScroll(store, "HistoryScroll", null, onSetScroll);
    },
    [children],
    true,
  );

  return (
    <RouterHistory
      onBackward={async () => {
        const data = await getStorageValue(
          "history:data" + (store ? `:${store}` : ""),
        );
        if (onLoadData && data) onLoadData(data);
        if (data) {
          clearStorageValue("history:data" + (store ? `:${store}` : ""));
        }
      }}
      onLocationChange={() => {
        if (preSaveData) {
          preSaveData(window.location.pathname);
        }
        if (saveData) {
          for (const prop in saveData)
            if (typeof saveData[prop] == "function") {
              saveData[prop] = saveData[prop]();
            }
        }
        logT(
          "HistoryScroll",
          "save scroll data =",
          window.pageYOffset,
          "additional data =",
          saveData,
          "store =",
          store,
        );
        if (saveData)
          setStorageValue(
            "history:data" + (store ? `:${store}` : ""),
            saveData,
          );
        setStorageValue(
          "history:scroll" + (store ? `:${store}` : ""),
          window.pageYOffset,
        );
      }}
    >
      {children}
    </RouterHistory>
  );
};
