import { useEffect } from "react";

const useResize = (callback) => {
  useEffect(() => {
    const handleResize = () => {
      callback(window.innerWidth, window.innerHeight);
    };

    // Initial callback to get the current window size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [callback]);
};

export default useResize;
