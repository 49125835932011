import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../global/Global";
import CheckboxSwitcher from "./CheckboxSwitcher";
import TabsSwitcher from "./TabsSwitcher";

const Filters = ({
  user,
  fetchData,
  categorySwitcher,
  setCategorySwitcher,
  oldUnpopularSwitcher,
  recentPopularSwitcher,
  mostCommented,
  periodSwitcher,
  postsPeriod,
  setPostsPeriod,
  title,
  titleLink,
  titleButton,
  titleClick,
  titleClass,
  tag,
  tagStatusLoaded,
  tagSubscribed,
  setTagSubscribed,
  showDisplayMode,
  displayMode,
  setDisplayMode,
  forumid,
}) => {
  const navigate = useNavigate();

  const setDisplayModeInternal = (mode) => {
    logT("filter", "changed display mode", mode);
    setCookie("displaymode", mode, 30);
    setDisplayMode(mode);
    if (fetchData) fetchData(true);
  };

  const oldPopularOptions = {
    0: __("New"),
    1: __("Popular"),
  };
  if (oldUnpopularSwitcher) {
    oldPopularOptions[2] = __("Old");
  }

  return (
    <>
      {forumid ? (
        <TabsSwitcher
          slow={true}
          value={"section"}
          onChange={(value) => {
            if (value == "forum") {
              navigate(`/forums/${forumid}`);
            }
          }}
          values={{
            forum: __("List"),
            section: __("Posts"),
          }}
        />
      ) : null}
      {recentPopularSwitcher ? (
        <TabsSwitcher
          slow={true}
          value={categorySwitcher}
          onChange={(value) => {
            const mode = parseInt(value);
            setCookie("categoryswitcher", mode, 30);
            setCategorySwitcher(mode);
            if (fetchData) fetchData(true);
          }}
          values={oldPopularOptions}
        />
      ) : null}

      {mostCommented ||
      (periodSwitcher && (categorySwitcher != 0 || !recentPopularSwitcher)) ? (
        <div
          className={
            "categorySwitcher" + (recentPopularSwitcher ? " withTabs" : "")
          }
        >
          <div>{__("Period")}:</div>
          <select
            value={postsPeriod}
            onChange={(event) => {
              const period = parseInt(event.target.value);
              setCookie("postsperiod", period, 30);
              setPostsPeriod(period);
              if (fetchData) fetchData(true);
            }}
          >
            <option value="1">{__("One day")}</option>
            <option value="3">3 {__("Last Days")}</option>
            <option value="7">{__("Week")}</option>
            <option value="30">{__("Month")}</option>
            <option value="90">3 {__("Months")}</option>
            <option value="180">6 {__("Months")}</option>
            <option value="365">1 {__("Year")}</option>
            <option value="1000">{__("All time")}</option>
          </select>
        </div>
      ) : null}
      <div className="titleText">
        {title ? (
          !titleButton ? (
            <Link to={titleLink}>{title}:</Link>
          ) : (
            <a className={titleClass} onClick={titleClick}>
              {titleButton} {title}:
            </a>
          )
        ) : null}
        {tag ? (
          <div className="flex row center-column center-row wrap">
            {user?.userid > 0 && tagStatusLoaded ? (
              <div
                className={
                  "clickButton tagSubscriptionButton" +
                  (tagSubscribed ? " unsubscribe" : " subscribe")
                }
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  const token = getCookie("token");
                  const userid = parseInt(getCookie("userid"));
                  const data = await window.TALKVIOAPI("subscribeTag", {
                    userid,
                    token,
                    tag,
                  });
                  window.displayError(data);
                  if (data.success) {
                    setTagSubscribed(data.subscribe);
                    if (fetchData) fetchData();
                  }
                }}
              >
                {tagSubscribed ? __("Unsubscribe") : __("Subscribe")}
              </div>
            ) : null}
            <Link to={"/tag/" + tag}>#{tag}:</Link>
            {user?.userid > 0 && tagStatusLoaded ? (
              <div
                className={
                  "clickButton tagBlockButton" +
                  (user.blocktags?.split(",").includes(tag)
                    ? " unblock"
                    : " blocked")
                }
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  const token = getCookie("token");
                  const userid = parseInt(getCookie("userid"));
                  const data = await window.TALKVIOAPI("blockTag", {
                    userid,
                    token,
                    tag,
                  });
                  window.displayError(data);
                  if (data.success) {
                    if (fetchData) fetchData();
                  }
                }}
              >
                {user.blocktags?.split(",").includes(tag)
                  ? __("Unblock")
                  : __("Block")}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {showDisplayMode ? (
        <div className="direction">
          <CheckboxSwitcher
            text={__("Posts")}
            value={displayMode == 0 || displayMode == 1}
            onSwitch={(checked) => {
              if (checked) {
                if (displayMode == 2) setDisplayModeInternal(0);
                else setDisplayModeInternal(1);
              } else {
                setDisplayModeInternal(2);
              }
            }}
          />
          <CheckboxSwitcher
            text={__("Comments")}
            value={displayMode == 0 || displayMode == 2}
            onSwitch={(checked) => {
              if (checked) {
                if (displayMode == 1) setDisplayModeInternal(0);
                else setDisplayModeInternal(2);
              } else {
                setDisplayModeInternal(1);
              }
            }}
          />
        </div>
      ) : null}
    </>
  );
};
export default Filters;
