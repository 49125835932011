const getNoficiationsPermission = (callback) => {
  if (!("Notification" in window)) {
    return;
  }
  if (
    Notification.permission !== "denied" &&
    Notification.permission !== "granted"
  ) {
    Notification.requestPermission(function (permission) {
      logT("notifications", "Pemission to notifications granted");
      if (callback) callback();
    });
  }
};

const processNotification = (message) => {
  const options = {
    body: message.message,
    icon: message.icon,
  };
  try {
    const notification = new Notification(message.title, options);
  } catch (err) {
    // Todo serviceworker notifications
  }
};

const sendNotification = (message) => {
  if (!("Notification" in window)) {
    return;
  } else if (Notification.permission === "granted") {
    processNotification(message);
  } else if (Notification.permission !== "denied") {
    getNoficiationsPermission(() => {
      processNotification(message);
    });
  }
};

export { getNoficiationsPermission, sendNotification };
