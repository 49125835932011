import React, { useState, useEffect, useRef } from "react";

const AskRegistation = () => {
  const [display, setDisplay] = useState(true);

  return (
    <>
      {display ? (
        <div
          onClick={() => {
            setDisplay(false);
            if (window.needRegistationAction)
              window.needRegistationAction(true);
          }}
          className="askRegistationBlock"
        >
          <div>{__("Register or login to get following features")}:</div>
          <div>– {__("create your own posts")}</div>
          <div>– {__("answer in the comments")}</div>
          <div>– {__("vote for best content")}</div>
          <div>– {__("show your relation to the users (karma)")}</div>
          <div>– {__("subscribe to intersting topics and authors")}</div>
          <div>– {__("and much much more!")}</div>
          <div>
            <br />
            {__("you can click on this block to open registation / login")} ;)
          </div>
        </div>
      ) : null}
    </>
  );
};
export default AskRegistation;
