const getStorageValue = (key) =>
  new Promise((resolve) => {
    const storage = window.localStorage;
    // Check if localStorage is available
    if (!storage) {
      logT("storage", "localStorage is unavailable.");
      return;
    }

    const localStoreValue = window.localStorage.getItem(key);
    if (localStoreValue) {
      resolve(JSON.parse(localStoreValue));
      return;
    } else {
      const dbName = "talkvio";
      const dbVersion = 1;
      const request = indexedDB.open(dbName, dbVersion);
      request.onerror = (event) => {
        console.error("Database error:", event.target.error);
      };
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const objStore = db.createObjectStore("talkvio", {});
      };
      request.onsuccess = async (event) => {
        const db = event.target.result;
        const request = await db
          .transaction(["talkvio"], "readwrite")
          .objectStore("talkvio")
          .get(key);
        request.onsuccess = () => {
          resolve(request.result);
        };
      };
    }
  });

const setStorageValue = (key, value, forceIndexDB = false) => {
  const storage = window.localStorage;
  // Check if localStorage is available
  if (!storage) {
    logT("storage", "localStorage is unavailable.");
    return;
  }

  const storeIndexDB = () => {
    // clear this key from localstorage
    window.localStorage.removeItem(key);

    const dbName = "talkvio";
    const dbVersion = 1;
    const request = indexedDB.open(dbName, dbVersion);
    request.onerror = (event) => {
      console.error("Database error:", event.target.error);
    };
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const objStore = db.createObjectStore("talkvio", {});
    };
    request.onsuccess = (event) => {
      const db = event.target.result;
      const request = db
        .transaction(["talkvio"], "readwrite")
        .objectStore("talkvio")
        .put(value, key);
    };
  };

  // local storage by default
  if (!forceIndexDB) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      // clear indexDB value if exists
      if (indexedDB) {
        const dbName = "talkvio";
        const dbVersion = 1;
        const request = indexedDB.open(dbName, dbVersion);
        request.onerror = (event) => {
          console.error("Database error:", event.target.error);
        };
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          const objStore = db.createObjectStore("talkvio", {});
        };
        request.onsuccess = (event) => {
          const db = event.target.result;
          const request = db
            .transaction(["talkvio"], "readwrite")
            .objectStore("talkvio")
            .delete(key);
        };
      }
    } catch (err) {
      storeIndexDB();
    }
  } else {
    storeIndexDB();
  }
};

const clearStorageValue = (key) => {
  const storage = window.localStorage;
  // Check if localStorage is available
  if (!storage) {
    logT("storage", "localStorage is unavailable.");
    return;
  }

  window.localStorage.removeItem(key);
  const dbName = "talkvio";
  const dbVersion = 1;
  const request = indexedDB.open(dbName, dbVersion);
  request.onerror = (event) => {
    console.error("Database error:", event.target.error);
  };
  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    const objStore = db.createObjectStore("talkvio", {});
  };
  request.onsuccess = (event) => {
    const db = event.target.result;
    const request = db
      .transaction(["talkvio"], "readwrite")
      .objectStore("talkvio")
      .delete(key);
  };
};

export { getStorageValue, setStorageValue, clearStorageValue };
