import React from "react";
import Maskot404 from "../404_error_maskot.png";

export default ({}) => {
  return (
    <div className="error-page typicalBlock">
      <div className="notFoundText">
        {__(
          "Oops! This page haven't found, please go back to main page or try again.",
        )}
      </div>
      <img src={Maskot404} />
    </div>
  );
};
