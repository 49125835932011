import React, { useContext, useState } from "react";

import { LoginContext } from "./Login";
import GoogleLogin from "./GoogleLogin";
import loadScript, { loadScriptText } from "../global/LoadScript";
import Tooltip from "./Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faGoogle, faVk } from "@fortawesome/free-brands-svg-icons";

const LoginForm = ({ displayLogin = false, setRef = null, onClose = null }) => {
  const { user, doLogin, possiblePreLogin, doRegistation } =
    useContext(LoginContext);
  const [isRegistation, setRegistation] = useState(false);
  const [doingRegistation, setDoingRegistration] = useState(false);

  logT("LoginForm", "user", user);

  return (
    !(user && user.userid) &&
    displayLogin &&
    !possiblePreLogin && (
      <noindex>
        <div className="login-container" ref={setRef}>
          <div className="closePanel">
            <FontAwesomeIcon
              icon={faXmark}
              className="closeButton"
              onClick={() => {
                if (onClose) onClose();
              }}
            ></FontAwesomeIcon>
          </div>
          <form>
            <input
              type="text"
              placeholder={
                isRegistation
                  ? __("Email")
                  : __("Email") + " " + __("or") + " " + __("Username")
              }
              name="email"
              id="loginEmail"
              required
            />
            <input
              type="password"
              placeholder={__("Password")}
              name="password"
              id="loginPassword"
              required
            />
            {isRegistation ? (
              <input
                type="password"
                placeholder={__("Confirm your password")}
                name="passwordConfirm"
                id="loginPasswordConfirm"
                required
              />
            ) : null}
            {isRegistation ? (
              <input
                type="text"
                placeholder={__("Username")}
                name="username"
                id="loginUsername"
                required
              />
            ) : null}
            {!isRegistation ? (
              <div className="loginButtonRow">
                <button
                  type="submit"
                  onClick={(event) => {
                    event.preventDefault();
                    const email = document
                      .getElementById("loginEmail")
                      .value?.trim();
                    const password =
                      document.getElementById("loginPassword").value;
                    doLogin({ login: email, password: password });
                  }}
                >
                  {__("Login")}
                </button>
                <div id="vk_auth"></div>
                <Tooltip hint={__("Enter or register using VK account")}>
                  <div
                    className="vkLogin"
                    onClick={() => {
                      const clientId = "51750085"; // Your VKontakte App's Client ID
                      const redirectUri =
                        process.env.NODE_ENV == "production"
                          ? "https://talkvio.com/vklogin"
                          : "http://localhost/vklogin"; // Callback URL on your server
                      const vkAuthUrl = `https://oauth.vk.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email&v=5.131`;
                      window.location.href = vkAuthUrl;
                    }}
                  >
                    <FontAwesomeIcon icon={faVk} />
                  </div>
                </Tooltip>
                <Tooltip hint={__("Enter or register using Facebook account")}>
                  <div
                    className="facebookLogin"
                    onClick={() => {
                      loadScriptText(
                        `
                                window.fbAsyncInit = function() {
                                    FB.init({
                                    appId      : '838520937649655',
                                    cookie     : true,
                                    xfbml      : true,
                                    version    : 'v18.0'
                                    });
                                    
                                    FB.AppEvents.logPageView();   
                                    
                                };
                            `,
                        () => {
                          loadScript(
                            "https://connect.facebook.net/en_US/sdk.js",
                            () => {
                              window.FB.getLoginStatus(function (response) {
                                if (response.status == "connected") {
                                  const accessToken =
                                    response.authResponse.accessToken;
                                  doLogin({ facebookToken: accessToken });
                                } else {
                                  logT("facebook", "login");
                                  window.FB.login(
                                    function (response) {
                                      if (response.authResponse) {
                                        const accessToken =
                                          response.authResponse.accessToken;
                                        logT("facebook", "token", accessToken);
                                        doLogin({ facebookToken: accessToken });
                                      } else {
                                        logT("facebook", "Login cancelled.");
                                      }
                                    },
                                    { scope: "email,public_profile" },
                                  ); // Specify the required permissions here.
                                }
                              });
                            },
                            "facebook-jssdk",
                          );
                        },
                      );
                      // doLogin({ facebookToken: 'a' })
                    }}
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </div>
                </Tooltip>
                <Tooltip
                  hint={__("Enter or register using Google account")}
                  maxWidth={100}
                >
                  <GoogleLogin
                    onLogin={(data) => {
                      doLogin({ credential: data?.credential });
                    }}
                  >
                    <div className="googleLogin">
                      <FontAwesomeIcon icon={faGoogle} />
                    </div>
                  </GoogleLogin>
                </Tooltip>
              </div>
            ) : null}
            {!isRegistation ? (
              <button
                onClick={(event) => {
                  event.preventDefault();
                  setRegistation(true);
                }}
              >
                {__("Need registation")}
              </button>
            ) : null}
            {isRegistation ? (
              <>
                {!doingRegistation ? (
                  <button
                    type="submit"
                    onClick={(event) => {
                      event.preventDefault();
                      setDoingRegistration(true);
                      loadScript(
                        "https://www.google.com/recaptcha/api.js?render=6Lf4MuEmAAAAAA7m_k5n4_gPgn1QYDChtzA3sUTX",
                        () => {
                          window.grecaptcha.ready(function () {
                            window.grecaptcha
                              .execute(
                                "6Lf4MuEmAAAAAA7m_k5n4_gPgn1QYDChtzA3sUTX",
                                {
                                  action: "submit",
                                },
                              )
                              .then(async function (token) {
                                const email = document
                                  .getElementById("loginEmail")
                                  .value?.trim();
                                const password =
                                  document.getElementById(
                                    "loginPassword",
                                  ).value;
                                const passwordConfirm = document.getElementById(
                                  "loginPasswordConfirm",
                                ).value;
                                const username = document
                                  .getElementById("loginUsername")
                                  .value?.trim();
                                if (!email || email.length < 2) {
                                  setDoingRegistration(false);
                                  window.showError(
                                    __("Error"),
                                    __(
                                      "You doesn't fill the email, please check the email field",
                                    ),
                                  );
                                  return;
                                }
                                if (!password || password.length < 8) {
                                  setDoingRegistration(false);
                                  window.showError(
                                    __("Error"),
                                    __("You doesn't fill the password.") +
                                      " " +
                                      __("Or your password length less than") +
                                      " 8 " +
                                      __("symbols") +
                                      ".",
                                  );
                                  return;
                                }
                                if (!username || username.length < 3) {
                                  setDoingRegistration(false);
                                  window.showError(
                                    __("Error"),
                                    __("You doesn't fill the username.") +
                                      " " +
                                      __("Minimum username length") +
                                      ": 3 " +
                                      __("symbols") +
                                      ".",
                                  );
                                  return;
                                }
                                if (password !== passwordConfirm) {
                                  setDoingRegistration(false);
                                  window.showError(
                                    __("Error"),
                                    __(
                                      "Your password and confirm password are not identical, please check them",
                                    ),
                                  );
                                  return;
                                }
                                setDoingRegistration(true);
                                await doRegistation({
                                  login: email,
                                  password: password,
                                  username,
                                  token,
                                });
                                setDoingRegistration(false);
                              });
                          });
                        },
                      );
                    }}
                  >
                    {__("Register")}
                  </button>
                ) : (
                  <div className="doingRegistation">
                    {__("Registation in progress, please wait")}...
                  </div>
                )}
              </>
            ) : null}
            {isRegistation ? (
              <button
                onClick={(event) => {
                  event.preventDefault();
                  setRegistation(false);
                }}
              >
                {__("Back to Login")}
              </button>
            ) : null}
          </form>
          {isRegistation ? (
            <div className="captchaText">
              This site is protected by reCAPTCHA and the Google &nbsp;
              <a href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}
              and &nbsp;
              <a href="https://policies.google.com/terms">
                Terms of Service
              </a>{" "}
              apply.
            </div>
          ) : null}
          <div
            className="restore"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              const data = await window.TALKVIOAPI("restorePassword", {
                email: document.getElementById("loginEmail").value?.trim(),
              });
              if (!data.success && data.error) {
                window.displayError(data);
              } else {
                window.showOk(
                  __("Confirmation"),
                  __("Confirmation sended to your email") +
                    ": " +
                    document.getElementById("loginEmail").value?.trim(),
                );
              }
            }}
          >
            {__("I forgot my password")}
          </div>
          <div className="why-login-containter">
            <div>{__("Register or login to get following features")}:</div>
            <div>– {__("create your own posts")}</div>
            <div>– {__("answer in the comments")}</div>
            <div>– {__("vote for best content")}</div>
            <div>– {__("show your relation to the users (karma)")}</div>
            <div>– {__("subscribe to intersting topics and authors")}</div>
            <div>– {__("and much much more!")}</div>
          </div>
        </div>
      </noindex>
    )
  );
};
export default LoginForm;
