function availableLocales() {
  return {
    ru: "Русский",
    en: "English",
  };
}

function availableLanguage(lang) {
  const langs = availableLocales();
  return langs[lang];
}

function getPreferredLanguage() {
  let language;
  const defaultLanguage = "ru";

  if (window.forceLanguage) {
    return window.forceLanguage;
  }

  if (typeof Intl !== "undefined") {
    try {
      if (!window.intlLanguage) {
        window.intlLanguage = Intl.NumberFormat().resolvedOptions().locale;
      }
      language = window.intlLanguage;
      language = language.split("-")[0];
      if (availableLanguage(language)) return language;
    } catch (err) {
      console.error("Cannot get locale from Intl");
    }
  }

  if (window.navigator.languages) {
    language = window.navigator.languages[0];
    language = language.split("-")[0];
    if (availableLanguage(language)) return language;
  } else if (window.navigator.userLanguage || window.navigator.language) {
    language = window.navigator.userLanguage || window.navigator.language;
    language = language.split("-")[0];
    if (availableLanguage(language)) return language;
  }

  return defaultLanguage;
}

export { getPreferredLanguage, availableLanguage, availableLocales };

const translate = (word) => {
  const translation =
    process.env?.TRANSLATIONS?.[getPreferredLanguage()]?.[word];
  if (translation === undefined) {
    return word;
  }
  return translation;
};

export default translate;
