function getClosestParentByClass(element, className) {
  while (element && !element.classList?.contains(className)) {
    element = element.parentElement;
  }
  return element;
}

function isContainTag(element, tag, root) {
  while (element && element.tagName.toLowerCase() != tag) {
    if (root && element == root) {
      return null;
    }
    element = element.parentElement;
  }
  return element;
}

export { getClosestParentByClass, isContainTag };
